import { InstantLoanStateType } from "../../../Reducers/types"

export const validatePhoneParser = (state: InstantLoanStateType["mobileVerification"]) => {
  return JSON.stringify({ phoneNumber: state.mobileNumber })
}

export const postValidatePhoneCompletionParser = (
  mobileState: InstantLoanStateType["mobileVerification"],
  pin: number
) => {
  return JSON.stringify({ phoneNumber: mobileState.mobileNumber, pin })
}
