import { useEffect, useMemo, useRef, useState } from "react"
import { parseFlinksURLString } from "./helpers"
import type { FlinksDataStateType } from "../../../Helpers/Reducers/FlinksData/types"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { v4 as uuidv4 } from 'uuid';

import "./style.css"

type FlinksProps = {
  src: string
  institutionId?: string
  clientEmail: string
  clientId: number
  onSuccess: () => void
  onStep: (data: FlinksDataStateType) => Promise<boolean>
  onExit: () => void
}

export function Flinks(props: FlinksProps) {
  const flinksRef = useRef<HTMLIFrameElement | null>(null)
  const flinksData = useRef<FlinksDataStateType>({ steps: [] })
  const [visible, setVisible] = useState(false)
  const [requestIdBypass] = useState(uuidv4());

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {

      if(e.source !== flinksRef.current?.contentWindow) return;

      let message = typeof e.data === "string" ? JSON.parse(e.data) : e.data;
      const institution = props.institutionId || message?.institution?.toString();
      const flinksCode = message.flinksCode;

      if (message.step === "APP_MOUNTED") {
        setVisible(true)
        return
      }

      if (message.step === "COMPONENT_BACK_SESSION") {
        props.onExit()
        return
      }

      if (message.step && message.institution && message.step === "COMPONENT_LOAD_CREDENTIAL") {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_banking_bank_selected",
              properties: {
                bankInstitution: props.institutionId || message.institution.toString(),
              },
            },
          },
        })
      }

      if (message.step === "SUBMIT_CREDENTIAL") {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_banking_flinks_submitted",
              properties: {
                bankInstitution: props.institutionId || message.institution,
              },
            },
          },
        })
      }

      if (!institution) {
        console.warn("[instituion is empty] Message from flinks: ", message)
        return
      }

      if(message.step) {
        flinksData.current.steps.push({ date: (new Date()).toISOString(), institution, step: message.step })
      }
      
      const steps = flinksData.current.steps
      flinksData.current.steps = []

      const success = await props.onStep({
        steps,
        institution,
        loginId: message?.loginId,
        requestId: requestIdBypass,
        requestIdUpdated: message.step === "REDIRECT" ? message?.requestId : undefined,
        flinksCode
      })

      if (!success) {
        flinksData.current.steps = [...steps, ...flinksData.current.steps]
      }

      if (message.loginId && message.step === "REDIRECT") {
        props.onSuccess()
      }

    }

    window.addEventListener("message", messageEventListener)

    return () => {
      window.removeEventListener("message", messageEventListener)
    }
  }, [])

  return (
    <div
      className="sm:absolute flex justify-center items-center left-0 right-0 z-10 sm:-mx-[16.5px] h-[650px] max-w-[622px]"
      style={{ maxHeight: "calc(100vh - 55px)" }}
    >
      <div className={`loading-indicator ${visible ? "loading-indicator--hidden" : ""}`}></div>
      <iframe
        style={{ visibility: visible ? "visible" : "hidden" }}
        ref={flinksRef}
        id="flinks-connect"
        className="relative z-1 w-full h-full"
        src={parseFlinksURLString(
          props.src,
          {
            contactId: props.clientId,
            email: props.clientEmail,
            date: new Date().toLocaleDateString("fr-CA"),
          },
          props.institutionId
        )}
      />
    </div>
  )
}
