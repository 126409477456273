import { BankType } from "./Reducers/types"

export function setRecentlyUsedInstitution(bank: BankType, userId?: string | number) {
  if (!userId) return false

  const banks = [bank]
  const expireDate = new Date()
  expireDate.setHours(expireDate.getHours() + 1)

  try {
    window.localStorage.setItem(`${userId}-recentlyUsedInstitutions`, JSON.stringify({ banks, expireAt: expireDate }))
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

export function getRecentlyUsedInstitution(userId?: string | number) {
  if (!userId) return []

  try {
    const recentlyUsedInstitutionsStr = window.localStorage.getItem(`${userId}-recentlyUsedInstitutions`)
    if (!recentlyUsedInstitutionsStr) {
      return []
    }
    const data = JSON.parse(recentlyUsedInstitutionsStr) as { banks: BankType[]; expireAt: string }

    if (Date.now() >= new Date(data.expireAt).valueOf()) {
      clearRecentlyUsedInstitutions(userId)
      return []
    }

    return data.banks
  } catch (error) {
    console.warn(error)
    return []
  }
}

export function clearRecentlyUsedInstitutions(userId?: string | number) {
  try {
    if (!userId) return false
    window.localStorage.removeItem("recentlyUsedInstitutions")
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}
