import { PropsWithChildren, useEffect, useRef, useState } from "react"
import "./style.css"

export const StepsFormContainer = (props: PropsWithChildren<{}>) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const boxRef = useRef<HTMLInputElement>(null)

  const handleScroll = () => {
    const position = Number(boxRef.current?.scrollTop)
    setScrollPosition(position)
  }

  useEffect(() => {
    handleScroll()
  }, [])

  return (
    <div
      ref={boxRef}
      className={`steps-container${scrollPosition > 0 ? " scroll" : ""}`}
      onScroll={handleScroll}
    >
      {props.children}
    </div>
  )
}
