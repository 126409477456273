import { createContext, useReducer, Dispatch } from "react"

type MessagesStateType = {
  applicationReviewMessages: string[]
}

type MessagesActionType = {
  type: "setApplicationReviewMessages"
  payload: {
    applicationReviewMessages: string[]
  }
}

const initialMessagesState: MessagesStateType = {
  applicationReviewMessages: [
    `We’re reviewing your application...`,
    `We’re reviewing your application...`,
    `We’re reviewing your application...`,
  ],
}

const messagesReducer = (state: MessagesStateType, action: MessagesActionType) => {
  switch (action.type) {
    case "setApplicationReviewMessages":
      return {
        ...state,
        applicationReviewMessages: action.payload.applicationReviewMessages,
      }
    default:
      return state
  }
}

export const useMessagesReducer = () => useReducer(messagesReducer, initialMessagesState)

export const MessagesContext = createContext<{
  state: MessagesStateType
  dispatch: Dispatch<MessagesActionType>
}>({
  state: initialMessagesState,
  dispatch: (_action) => {},
})
