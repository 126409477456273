import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  LegacyRef,
} from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  AddressAutocomplete,
  AddressAutocompleteDetailsType,
  Button,
  InputGroup,
  SelectInput,
  TextInput,
  ValidationMessage,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import "./style.css"

import { listParser } from "../../../Helpers/Parsers/List"

import { UserContext } from "../../../Helpers/Reducers/User"
import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import {
  convertCountryCodeToId,
  convertStateIdToProvinceCode,
  convertStateIdToStateNaming,
  convertStateToId,
  provinceCodeisNotApplicable,
  residentialDetailsParser,
  isUS,
  isCanada,
  convertStateIdToSlug,
} from "../../../Helpers/Parsers/Steps/ResidentialDetails"

import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"

import {
  residentialDetailsValidators,
  hasAddressAptNumber,
} from "../../../Helpers/Validators/Steps/ResidentialDetails"
import { validateValues } from "../../../Helpers/Validators"

import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { InstantLoanStateType, ProvinceType } from "../../../Helpers/Reducers/types"


export function ResidentialDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [homeAddressReloadKey, setHomeAddressReloadKey] = useState(0)
  const [oldHomeAddressReloadKey, setOldHomeAddressReloadKey] = useState(0)

  const navigate = useNavigate()

  const {
    state: { residentialDetails, loanParameters, isApp, provinces },
    dispatch,
  } = useContext(InstantLoanContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const [lists, setLists] = useState({
    addressResidenceType: [],
    addressOwningStatus: [],
    addressStateUS: [],
    addressStatus: [],
    addressStateCanada: [],
    addressSinceWhen: [],
    addressCountry: [],
  })

  const {
    state: { user },
  } = useContext(UserContext)
  const { pathname, search } = useLocation()
  const path = pathname.substring(1)

  const [useManualAddress, setUseManualAddress] = useState(false)
  const [useManualOldAddress, setUseManualOldAddress] = useState(false)

  const [licenseDisplay, setLicenseDisplay] = useState<boolean|null>()
  const [selectedProvince, setSelectedProvince] = useState<ProvinceType|null>()
  const [height, setHeight] = useState(0);

  const licenseIframe = useRef<HTMLIFrameElement>()

  const toggleManualAddress = () => setUseManualAddress(!useManualAddress)
  const toggleManualOldAddress = () => setUseManualOldAddress(!useManualOldAddress)

  // make sure address errors are visible
  useEffect(() => {
    if (
      errors.addressAutocomplete === undefined &&
      Object.keys(errors).some((key) => key.startsWith("address"))
    ) {
      setUseManualAddress(true)
    }
    if (
      errors.oldAddressAutocomplete === undefined &&
      Object.keys(errors).some((key) => key.startsWith("oldAddress"))
    ) {
      setUseManualOldAddress(true)
    }
  }, [errors])

  // details cleanup is needed for submit, syncup with progress etc
  const cleanupDetails = (details: typeof residentialDetails) => {
    // some BE artifacts
    delete details.oldAddressStateIdCA
    delete details.oldAddressStateIdUS
    delete details.oldAddressLatitude
    delete details.oldAddressLongitude
    delete details.addressLatitude
    delete details.addressLongitude

    details.addressOwningStatusId ||= ""

    let lessThanHalfAYear = String(details.addressSinceWhenId) === "1" || String(details.addressSinceWhenId) === "2"
    let key: keyof typeof details
    for (key in details)
      if (key.startsWith("old")) details[key] = lessThanHalfAYear ? details[key] || "" : undefined

    if (!isCanada(details.addressCountryId)) delete details.addressStateId

    if (Number(details.addressStatusId) !== 2) details.addressOwningStatusId = undefined

    return details
  }

  const handleValueChange =
    <T extends string | number | Date>(key: keyof typeof residentialDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "residentialDetails",
        payload: cleanupDetails({ ...residentialDetails, [key]: value }),
      })
    }

  const handleSubmit = () => {
    const residentialDetailsValues = cleanupDetails(residentialDetailsParser(residentialDetails))

    // validate residential details
    let validationErrors = validateValues(
      residentialDetailsValues,
      residentialDetailsValidators as {}
    )
    if (validationErrors) {
      // scroll to first document error
      setTimeout(() => document.querySelector(".global--input-error")?.scrollIntoView({ behavior: "smooth", block: "center" }))
      return setErrors(validationErrors)
    }

    // if selected province differs from user account province - we display province info
    if (
      !licenseDisplay &&
      String(user?.provinceId) !== String(residentialDetailsValues.addressStateId) &&
      isCanada(residentialDetailsValues.addressCountryId) &&
      !provinceCodeisNotApplicable(residentialDetailsValues.addressStateId)
    ) {
      setLicenseDisplay(true)
      // we set short rendering delay to force scroll top
      setTimeout(() => {
        document.getElementById("scroll-anchor")?.scrollIntoView({ block: "start" })
      })
      // post license loaded event
      postMessageSender({
        event: "marketing",
        payload: {
          status: "screen",
          data: {
            pathName: "app_new_license_info_loaded",
            categoryName: "Application",
            properties: {
              province: selectedProvince?.licensePageName,
            },
          },
        },
      })
      return
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_address_information_submitted",
        },
      },
    })

    if (
      licenseDisplay && String(user?.provinceId) !== String(residentialDetailsValues.addressStateId) &&
      isCanada(residentialDetailsValues.addressCountryId)
    )
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_new_license_info_submitted",
            properties: {
              province: selectedProvince?.licensePageName,
            },
          },
        },
      })

    setIsSubmitting(true)
    postStepRequest(2, residentialDetailsValues, token)
      .then(() => {

        if (licenseDisplay && String(user?.provinceId) !== String(residentialDetailsValues.addressStateId) && isCanada(residentialDetailsValues.addressCountryId)) {
          postMessageSender({
            event: "marketing",
            payload: {
              status: "track",
              data: {
                event: "app_new_license_info_completed",
                properties: {
                  province: selectedProvince?.licensePageName
                },
              },
            },
          });
        }

        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_address_information_completed",
            },
          },
        })
        goToNext(navigate, path)
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        setIsSubmitting(false);
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }

  // license iframe sends height of its content
  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.source !== licenseIframe.current?.contentWindow) {
        return;
      }

      if (!e.data) return
      let data = JSON.parse(e.data)
      if (data.event !== 'size') return
      setHeight(data.payload.height);
      // we remove listener to avoid infinite loop in webview
      // @ts-ignore
      // if (window.Android || /WebView|wv|^http.agent$/i.test(navigator.userAgent)) window.removeEventListener("message", listener);
    }
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    getStepRequest(2, token)
      .then(({ data, list }) => {
        // initialize options for selects
        for (let name in list) list[name] = listParser(list[name])
        setLists(list)
        const province = provinces.find(p => String(p.id) === String(data.addressStateId));
        setSelectedProvince(province)
        dispatch({
          step: "residentialDetails",
          payload: cleanupDetails({ ...data }),
        })
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        setIsLoading(false)
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }, [])

  const updateResidentialDetailsWithAutocompleteDetails = useCallback(
    (addressAutocompleteDetails: AddressAutocompleteDetailsType) => {
      if (!addressAutocompleteDetails) return

      const newStateId = convertStateToId(addressAutocompleteDetails.stateCode)

      if (addressAutocompleteDetails.stateCode && !provinceCodeisNotApplicable(newStateId)) {
        const province = provinces.find((p) => String(p.id) === String(newStateId))
        setSelectedProvince(province)

        dispatch({
          step: "homeStateChanged",
          payload: {
            province: addressAutocompleteDetails.stateCode,
          },
        })
      }

      dispatch({
        step: "residentialDetails",
        payload: cleanupDetails({
          ...residentialDetails,
          addressPostalCode: addressAutocompleteDetails.postalCode ?? "",
          addressCivicNumber: addressAutocompleteDetails.streetNumber ?? "",
          addressStreet: addressAutocompleteDetails.route ?? "",
          addressStateId: newStateId ?? "",
          addressCountryId: convertCountryCodeToId(addressAutocompleteDetails.country) ?? "",
          addressCity: addressAutocompleteDetails.locality ?? "",
        }),
      })

      setHomeAddressReloadKey((i) => i + 1)
    },
    [residentialDetails]
  )

  const updateOldResidentialDetailsWithAutocompleteDetails = useCallback(
    (addressAutocompleteDetails: AddressAutocompleteDetailsType) => {
      if (!addressAutocompleteDetails) return
      dispatch({
        step: "residentialDetails",
        payload: cleanupDetails({
          ...residentialDetails,
          oldAddressPostalCode: addressAutocompleteDetails.postalCode ?? "",
          oldAddressCivicNumber: addressAutocompleteDetails.streetNumber ?? "",
          oldAddressStreet: addressAutocompleteDetails.route ?? "",
          oldAddressStateId: convertStateToId(addressAutocompleteDetails.stateCode) ?? "",
          oldAddressCountryId: convertCountryCodeToId(addressAutocompleteDetails.country) ?? "",
          oldAddressCity: addressAutocompleteDetails.locality ?? "",
        }),
      })
      setOldHomeAddressReloadKey((i) => i + 1)
    },
    [residentialDetails]
  )

  const selectProvince = (value?: string | number) => {
    if (residentialDetails.addressStateId !== value) {
      const provinceCode = convertStateIdToProvinceCode(value as string)
      handleValueChange("addressStateId")(value)

      const province = provinces.find((p) => String(p.id) === String(value))
      setSelectedProvince(province)

      dispatch({
        step: "homeStateChanged",
        payload: {
          province: provinceCode,
        },
      })
    }
  }

  const errorBanner = () => {
    if (
      provinceCodeisNotApplicable(residentialDetails.addressStateId) &&
      residentialDetails.addressCountryId === "1"
    ) {
      return (
        <ValidationMessage className="m-[8px] w-full" status="error">
          <p className="caption-light-no-color" id="test-toast-error-province">
            Unfortunately, we currently do not operate in your province. We hope to change that in the near future and
            will contact you as soon as we are available!
          </p>
        </ValidationMessage>
      )
    }
    return null
  }

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_address_information_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        {errorBanner()}

        {selectedProvince && <iframe height={licenseDisplay ? height + 10 : 0} ref={licenseIframe as LegacyRef<HTMLIFrameElement>} src={`${process.env.REACT_APP_ICASH_BASE_URL}/licenses/${ selectedProvince.licensePageName }`} className={`w-full`}/> }
        <div className={`grid grid-cols-2 gap-x-[22px] mb-[22px] items-start sm:flex sm:flex-col sm:items-stretch ${licenseDisplay ? '!hidden' : 'block'}`}>
          <InputGroup className="grid grid-cols-2 gap-x-[22px] col-span-2 mb-[22px] pt-[22px] pb-[16px] px-[18px]">
            <p className="caption-light col-span-2">Home address</p>
            <AddressAutocomplete
              className="col-span-2"
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
              label="Full address"
              defaultValue={residentialDetails.addressAutocomplete}
              error={errors.addressAutocomplete}
              onChange={handleValueChange("addressAutocomplete")}
              onChangeAddressDetails={(addressDetails: any) => {
                updateResidentialDetailsWithAutocompleteDetails(addressDetails)
              }}
              id="test-autocomplete-address"
            />
            <p className="caption-medium -mt-2 mb-2 col-span-2">
              Can't find your address? Manually input it{" "}
              <span onClick={toggleManualAddress} className="underline cursor-pointer">
                here
              </span>
              . Note that we don't accept PO box addresses.
            </p>
            {useManualAddress ? (
              <Fragment key={homeAddressReloadKey}>
                <div className="col-span-2 grid grid-cols-10 gap-x-[22px] mt-2">
                  <TextInput
                    className="col-span-3 sm:col-span-4"
                    label="Civic number"
                    value={residentialDetails.addressCivicNumber}
                    error={errors.addressCivicNumber}
                    onChange={handleValueChange("addressCivicNumber")}
                    disabled={isSubmitting}
                  />
                  <TextInput
                    className="col-span-4 sm:col-span-6"
                    label="Street"
                    value={residentialDetails.addressStreet}
                    error={errors.addressStreet}
                    onChange={handleValueChange("addressStreet")}
                    disabled={isSubmitting}
                  />
                  <TextInput
                    className="col-span-3 sm:col-span-10"
                    label="City"
                    value={residentialDetails.addressCity}
                    error={errors.addressCity}
                    onChange={handleValueChange("addressCity")}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="col-span-2 grid grid-cols-10 gap-x-[22px] items-start">
                  <SelectInput
                    native={isApp ? false : undefined}
                    className="col-span-3 sm:col-span-6"
                    label="Province"
                    defaultValue={residentialDetails.addressStateId}
                    error={errors.addressStateId}
                    onChange={selectProvince}
                    options={
                      isUS(residentialDetails.addressCountryId)
                        ? lists.addressStateUS
                        : isCanada(residentialDetails.addressCountryId)
                        ? lists.addressStateCanada
                        : []
                    }
                    disabled={!isCanada(residentialDetails.addressCountryId)}
                    id="test-province-residential"
                  />
                  <TextInput
                    className="col-span-3 mt-8 sm:col-span-4"
                    label="Postal code"
                    value={residentialDetails.addressPostalCode}
                    error={errors.addressPostalCode}
                    onChange={handleValueChange("addressPostalCode")}
                    disabled={isSubmitting}
                  />
                  <SelectInput
                    native={isApp ? false : undefined}
                    className="col-span-4 sm:col-span-10"
                    label="Country"
                    defaultValue={residentialDetails.addressCountryId}
                    error={errors.addressCountryId}
                    onChange={handleValueChange("addressCountryId")}
                    options={lists.addressCountry}
                    id="test-country-residential"
                    disabled={isSubmitting}
                  />
                </div>
                {/* <SelectInput
                  native={isApp ? false : undefined}
                  id="residential-details--residence-type-dropdown"
                  label="Residence type"
                  defaultValue={residentialDetails.addressResidenceTypeId}
                  error={errors.addressResidenceTypeId}
                  onChange={handleValueChange("addressResidenceTypeId")}
                  options={lists.addressResidenceType}
                  disabled={isSubmitting}
                /> */}
                <TextInput
                  optional
                  className="mt-8"
                  label="Apt / Suite / Unit #"
                  value={residentialDetails.addressAptNumber}
                  error={errors.addressAptNumber}
                  onChange={handleValueChange("addressAptNumber")}
                  disabled={isSubmitting}
                />
              </Fragment>
            ) : (
              <>
                {/* <SelectInput
                  native={isApp ? false : undefined}
                  label="Residence type"
                  id="residential-type"
                  defaultValue={residentialDetails.addressResidenceTypeId}
                  error={errors.addressResidenceTypeId}
                  onChange={handleValueChange("addressResidenceTypeId")}
                  options={lists.addressResidenceType}
                  disabled={isSubmitting}
                /> */}
                {/* {hasAddressAptNumber(residentialDetails.addressResidenceTypeId || "") && ( */}
                  <TextInput
                    optional
                    className="mt-8"
                    label="Apt / Suite / Unit #"
                    value={residentialDetails.addressAptNumber}
                    error={errors.addressAptNumber}
                    onChange={handleValueChange("addressAptNumber")}
                    disabled={isSubmitting}
                  />
                {/* )} */}
              </>
            )}
          </InputGroup>

          <SelectInput
            native={isApp ? false : undefined}
            label="Home ownership status"
            defaultValue={residentialDetails.addressStatusId}
            error={errors.addressStatusId}
            onChange={handleValueChange("addressStatusId")}
            options={lists.addressStatus}
            id="test-ownership-residential"
            disabled={isSubmitting}
          />
          {/* <TextInput
            className="mt-8 sm:mt-0 sm:min-w-[287px]"
            label="Monthly rent / mortgage cost"
            id="test-monthly-rent"
            value={residentialDetails.addressAmountRentMortgage}
            error={errors.addressAmountRentMortgage}
            inputMode="decimal"
            onChange={handleValueChange("addressAmountRentMortgage")}
            disabled={isSubmitting}
          /> */}

          {residentialDetails.addressSinceWhenId === "1" ||
          residentialDetails.addressSinceWhenId === "2" ? (
            <InputGroup className="grid grid-cols-2 gap-x-[22px] col-span-2 mb-[22px] pt-[22px] pb-[16px] px-[18px]">
              <SelectInput
                native={isApp ? false : undefined}
                label="How long have you been at this address?"
                id="test-address-since-when-id"
                defaultValue={residentialDetails.addressSinceWhenId}
                error={errors.addressSinceWhenId}
                onChange={handleValueChange("addressSinceWhenId")}
                options={lists.addressSinceWhen}
                disabled={isSubmitting}
              />
              <p className="caption-light col-span-2">Previous home address</p>
              <AddressAutocomplete
                className="col-span-2"
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                label="Previous address"
                id="test-old-autocomplete-address"
                defaultValue={residentialDetails.oldAddressAutocomplete}
                error={errors.oldAddressAutocomplete}
                onChange={handleValueChange("oldAddressAutocomplete")}
                onChangeAddressDetails={(addressDetails: any) => {
                  updateOldResidentialDetailsWithAutocompleteDetails(addressDetails)
                }}
              />
              <p className="caption-medium -mt-2 mb-2 col-span-2" id="test-here-old-address">
                Can't find your address? Manually input it&nbsp;
                <span onClick={toggleManualOldAddress} className="underline cursor-pointer">
                  here
                </span>
                . Note that we don't accept PO box addresses.
              </p>
              {useManualOldAddress && (
                <Fragment key={oldHomeAddressReloadKey}>
                  <div className="col-span-2 grid grid-cols-10 gap-x-[22px] items-start mt-2">
                    <TextInput
                      className="col-span-3 sm:col-span-4"
                      label="Civic number"
                      id="test-old-civic-number"
                      value={residentialDetails.oldAddressCivicNumber}
                      error={errors.oldAddressCivicNumber}
                      onChange={handleValueChange("oldAddressCivicNumber")}
                      disabled={isSubmitting}
                    />
                    <TextInput
                      className="col-span-4 sm:col-span-6"
                      label="Street"
                      id="test-old-street"
                      value={residentialDetails.oldAddressStreet}
                      error={errors.oldAddressStreet}
                      onChange={handleValueChange("oldAddressStreet")}
                      disabled={isSubmitting}
                    />
                    <TextInput
                      className="col-span-3 sm:col-span-10"
                      label="City"
                      id="test-old-city"
                      value={residentialDetails.oldAddressCity}
                      error={errors.oldAddressCity}
                      onChange={handleValueChange("oldAddressCity")}
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="col-span-2 grid grid-cols-10 gap-x-[22px] items-start">
                    <SelectInput
                      native={isApp ? false : undefined}
                      className="col-span-3 sm:col-span-6"
                      label="Province"
                      id="test-old-state"
                      defaultValue={residentialDetails.oldAddressStateId}
                      error={errors.oldAddressStateId}
                      onChange={handleValueChange("oldAddressStateId")}
                      options={
                        isUS(residentialDetails.oldAddressCountryId)
                          ? lists.addressStateUS
                          : isCanada(residentialDetails.oldAddressCountryId)
                          ? lists.addressStateCanada
                          : []
                      }
                      disabled={
                        !isCanada(residentialDetails.oldAddressCountryId) &&
                        !isUS(residentialDetails.oldAddressCountryId)
                      }
                    />
                    <TextInput
                      className="col-span-3 mt-8 sm:col-span-4"
                      label="Postal code"
                      id="test-old-postal-code"
                      value={residentialDetails.oldAddressPostalCode}
                      error={errors.oldAddressPostalCode}
                      onChange={handleValueChange("oldAddressPostalCode")}
                      disabled={isSubmitting}
                    />
                    <SelectInput
                      native={isApp ? false : undefined}
                      className="col-span-4 sm:col-span-10"
                      label="Country"
                      id="test-old-postal-country"
                      defaultValue={residentialDetails.oldAddressCountryId}
                      error={errors.oldAddressCountryId}
                      onChange={handleValueChange("oldAddressCountryId")}
                      options={lists.addressCountry}
                      disabled={isSubmitting}
                    />
                  </div>
                </Fragment>
              )}
              <div></div>
              <TextInput
                label="Apt / Suite / Unit #"
                className="mt-8"
                id="test-old-apt-number"
                optional
                value={residentialDetails.oldAddressAptNumber}
                error={errors.oldAddressAptNumber}
                onChange={handleValueChange("oldAddressAptNumber")}
                disabled={isSubmitting}
              />
            </InputGroup>
          ) : (
            <SelectInput
              native={isApp ? false : undefined}
              label="How long have you been at this address?"
              id="test-address-since-when-id"
              defaultValue={residentialDetails.addressSinceWhenId}
              error={errors.addressSinceWhenId}
              onChange={handleValueChange("addressSinceWhenId")}
              options={lists.addressSinceWhen}
              disabled={isSubmitting}
            />
          )}
          {residentialDetails.addressStatusId === "2" && (
            <SelectInput
              className="mt-4 sm:mt-0"
              native={isApp ? false : undefined}
              label="Mortgage status"
              id="mortgage-status"
              defaultValue={residentialDetails.addressOwningStatusId}
              error={errors.addressOwningStatusId}
              onChange={handleValueChange("addressOwningStatusId")}
              options={lists.addressOwningStatus}
              disabled={isSubmitting}
            />
          )}
        </div>
        <div className="flex sm:space-x-0 flex-col">
          <p className="caption mb-[32px] sm:mb-[24px]">
            By clicking Continue, I confirm that the information provided is current and accurate.
          </p>
          <div className="flex space-x-[22px] sm:flex-col-reverse sm:space-x-0">
            <Button
              className="flex-1 sm:mt-[22px] sm:py-[13px] sm:text-[16px] sm:hidden"
              size="large"
              onClick={() =>
                licenseDisplay ? setLicenseDisplay(false) : goToPrevious(navigate, path)
              }
              disabled={isSubmitting}
            >
              Previous step
            </Button>
            <Button
              className="flex-1 sm:py-[13px] sm:text-[16px]"
              size="large"
              appearance="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={
                !!Object.values(errors).join("") ||
                isSubmitting ||
                (provinceCodeisNotApplicable(residentialDetails.addressStateId) &&
                  residentialDetails.addressCountryId === "1")
              }
            >
              Continue
            </Button>
          </div>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}
