import { InstantLoanStateType } from "../../../Reducers/types"

export const incomeDetailsParser = (
  state: InstantLoanStateType["incomeDetails"]
): Partial<Record<keyof InstantLoanStateType["incomeDetails"], any>> => ({
  ...state,
  paymentMethod: parseInt(state.paymentMethod || ""),
  paymentSchedule: parseInt(state.paymentSchedule || ""),
  joinBankAccount: parseInt(state.joinBankAccount || ""),
  bankEtransfer: parseInt(state.bankEtransfer || ""),
  additionalIncome: parseInt(state.additionalIncome || ""),
  additionalIncomeAmount: parseFloat(state.additionalIncomeAmount || ""),
  additionalIncomeSource: parseInt(state.additionalIncomeSource || ""),
  additionalIncomeFrequency: parseInt(state.additionalIncomeFrequency || ""),
  incomeAmount: parseFloat(state.incomeAmount || ""),
  lastPayDate: state.lastPayDate?.toLocaleDateString("fr-CA"),
  nextPayDate: state.nextPayDate?.toLocaleDateString("fr-CA"),
})
