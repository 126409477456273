import { InstantLoanStateType } from "../../../Reducers/types"

export const employmentDetailsParser = (
  state: InstantLoanStateType["employmentDetails"]
): Partial<Record<keyof InstantLoanStateType["employmentDetails"], any>> => ({
  ...state,
  employmentStatus: parseInt(state.employmentStatus || ""),
  jobAddressStateId: parseInt(state.jobAddressStateId || ""),
  jobAddressLatitude: parseInt(state.jobAddressLatitude || ""),
  jobAddressLongitude: parseInt(state.jobAddressLongitude || ""),
  jobAddressCountryId: parseInt(state.jobAddressCountryId || ""),
  timeAtCurrentJob: parseInt(state.timeAtCurrentJob || ""),
  startClaimDate: state.startClaimDate?.toLocaleDateString?.("fr-CA") || "",
  endClaimDate: state.endClaimDate?.toLocaleDateString("fr-CA") || "",
  jobAddressPostalCode: state.jobAddressPostalCode
    ? `${state.jobAddressPostalCode.slice(0, 3).toUpperCase()} ${state.jobAddressPostalCode
        .replaceAll(" ", "")
        .slice(3)
        .toUpperCase()}`
    : "",
})
