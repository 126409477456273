export const validateValues = (
  values: Record<string, any>,
  validators: Record<string, (value: any, allValues: typeof values) => string | undefined>
) => {
  normalizeValues(values)

  const errors: Record<string, any> = {}
  let key, message
  for (key in values)
    if (values[key] !== undefined && (message = validators[key]?.(values[key], values)))
      errors[key] = message

  if (Object.keys(errors).length) return errors
}

// trim stringy values
export const normalizeValues = (values: Record<string, string | unknown>) => {
  for (let key in values) {
    let value = values[key]
    values[key] = typeof value === "string" ? value.trim() : value
  }
  return values
}
