import { InstantLoanStateType } from "../../../Reducers/types"

const convertionStateDict: Record<string, string> = {
  QC: "1",
  BC: "2",
  ON: "3",
  NS: "4",
  NB: "5",
  MB: "6",
  AB: "7",
  PE: "8",
  SK: "9",
  NL: "10",
  NU: "11",
  NT: "12",
  YT: "13",
}

const convertionStateIdDict: {
  [key: string]: {
    provinceCode: string
    slug?: string
    naming: "province" | "territory"
    isNotApplicable: boolean
  }
} = {
  "1": { provinceCode: "QC", naming: "province", isNotApplicable: true },
  "2": { provinceCode: "BC", slug: "britishcolumbia", naming: "province", isNotApplicable: false },
  "3": { provinceCode: "ON", slug: "ontario", naming: "province", isNotApplicable: false },
  "4": { provinceCode: "NS", slug: "novascotia", naming: "province", isNotApplicable: false },
  "5": { provinceCode: "NB", slug: "newbrunswick", naming: "province", isNotApplicable: false },
  "6": { provinceCode: "MB", slug: "manitoba", naming: "province", isNotApplicable: false },
  "7": { provinceCode: "AB", slug: "alberta", naming: "province", isNotApplicable: false },
  "8": {
    provinceCode: "PE",
    slug: "princeedwardisland",
    naming: "province",
    isNotApplicable: false,
  },
  "9": { provinceCode: "SK", naming: "province", isNotApplicable: true },
  "10": { provinceCode: "NL", naming: "province", isNotApplicable: true },
  "11": { provinceCode: "NU", naming: "province", isNotApplicable: true },
  "12": { provinceCode: "NT", naming: "territory", isNotApplicable: true },
  "13": { provinceCode: "YT", naming: "province", isNotApplicable: true },
}

export const convertStateIdToStateNaming = (stateId?: string) => {
  if (!stateId) {
    return "province"
  }
  return convertionStateIdDict[stateId]?.naming
}

export const convertStateIdToProvinceCode = (stateId?: string) => {
  if (!stateId) {
    return "1"
  }
  return convertionStateIdDict[stateId]?.provinceCode
}

export const convertStateIdToSlug = (stateId?: string) => {
  if (!stateId) {
    return ""
  }
  return convertionStateIdDict[stateId]?.slug
}

export const provinceCodeisNotApplicable = (addressStateId: string | undefined) => {
  if (!addressStateId) {
    return false
  }

  const notProvinceMatch = convertionStateIdDict[addressStateId]

  if (notProvinceMatch?.isNotApplicable === true) {
    return true
  }
  return false
}

export const convertStateToId = (state?: string) => {
  if (!state) {
    return "1"
  }
  return convertionStateDict[state]
}

export const convertCountryCodeToId = (countryCode?: string) => {
  if (countryCode === "CA") {
    return "1"
  } else {
    return "2"
  }
}

export const residentialDetailsParser = (
  state: InstantLoanStateType["residentialDetails"]
): Partial<Record<keyof InstantLoanStateType["residentialDetails"], any>> => ({
  ...state,
  addressStateId: parseInt(state.addressStateId || ""),
  addressCountryId: parseInt(state.addressCountryId || ""),
  addressResidenceTypeId: 13, // TODO FIXME create a new value
  addressStatusId: parseInt(state.addressStatusId || ""),
  addressSinceWhenId: parseInt(state.addressSinceWhenId || ""),
  oldAddressStateId: parseInt(state.oldAddressStateId || ""),
  oldAddressCountryId: parseInt(state.oldAddressCountryId || ""),
  hasAddressAptNumber: parseInt(state.hasAddressAptNumber || ""),
  hasOldAddressAptNumber: parseInt(state.hasOldAddressAptNumber || ""),
  addressLatitude: parseFloat(state.addressLatitude || ""),
  addressLongitude: parseFloat(state.addressLongitude || ""),
  addressOwningStatusId: parseInt(state.addressOwningStatusId || ""),
  addressAmountRentMortgage: 0,
  addressPostalCode: `${state.addressPostalCode
    ?.slice(0, 3)
    .toUpperCase()} ${state.addressPostalCode?.replaceAll(" ", "").slice(3).toUpperCase()}`,
  oldAddressStateIdCA: parseInt(state.oldAddressStateIdCA || ""),
  oldAddressStateIdUS: parseInt(state.oldAddressStateIdUS || ""),
  oldAddressLatitude: parseInt(state.oldAddressLatitude || ""),
  oldAddressLongitude: parseInt(state.oldAddressLongitude || ""),
  oldAddressPostalCode: isUS(state.oldAddressCountryId)
    ? state.oldAddressPostalCode?.replaceAll(" ", "")
    : `${state.oldAddressPostalCode?.slice(0, 3).toUpperCase()} ${state.oldAddressPostalCode
        ?.replaceAll(" ", "")
        .slice(3)
        .toUpperCase()}`,
})

export const isUS = (country: string | number | undefined) => Number(country) === 227
export const isCanada = (country: string | number | undefined) => Number(country) === 1
