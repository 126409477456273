import { InstantLoanStateType } from "../../../Reducers/types"
import { Validators } from "../../types"

export const financialDetailsValidators: Validators<InstantLoanStateType["financialDetails"]> = {
  purposeOfLoan(value, values) {
    if (!Number(value)) return "Required"
  },
  overdueLoans(value, values) {
    // if (!Number(value)) return "Required"
  },
  creditScoreEstimation(value, values) {
    if (!Number(value)) return "Required"
  },
}
