import { useContext, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Button, TextInput, CheckboxInput, PhoneInput } from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { agreementsParser, referenceDetailsParser } from "../../../Helpers/Parsers/Steps/ReferenceDetails"

import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"

import { referenceDetailsValidators } from "../../../Helpers/Validators/Steps/ReferenceDetails"
import { validateValues } from "../../../Helpers/Validators"

import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { UserContext } from "../../../Helpers/Reducers/User"

export function ReferenceDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})

  const navigate = useNavigate()

  const {
    state: { referenceDetails, loanParameters, isApp, marketingConsent, marketingConsentUpdated },
    dispatch,
  } = useContext(InstantLoanContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const {
    state: { user },
  } = useContext(UserContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  const handleValueChange =
    <T extends string | number | boolean>(key: keyof typeof referenceDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "referenceDetails",
        payload: { ...referenceDetails, [key]: value },
      })
    }

  const getAgreed = () =>
    referenceDetails.agreeToAccurateInfo && referenceDetails.agreeToPolicy && referenceDetails.agreeToShareInfo

  const handleSubmit = async () => {
    // validate details
    let validationErrors = validateValues(referenceDetails, referenceDetailsValidators as {})
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () => document.querySelector(".global--input-error")?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }

    setIsSubmitting(true)
    try {
      await postStepRequest(6, referenceDetailsParser(referenceDetails), token)

      await postStepRequest(7, agreementsParser(referenceDetails), token)

      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_agreement_completed",
          },
        },
      })

      goToNext(navigate, path)
    } catch (e: any) {
      toast(e.message || e, { type: "error" })
      setIsSubmitting(false)
      // scroll to top of document
      document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  useEffect(() => {
    document.body?.style.setProperty("overflow", "auto", "important")
    window.scrollTo(0, 0)

    getStepRequest(6, token)
      .then(({ data, list }) =>
        dispatch({
          step: "referenceDetails",
          payload: { ...data },
        })
      )
      .then(() => {
        setIsLoading(false)
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        setIsLoading(false)
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        <div className="grid grid-cols-2 gap-x-[22px] mb-[22px] items-start sm:flex sm:flex-col sm:items-stretch">
          <p className="caption-light col-span-2 mb-[32px] sm:mb-[32px]">
            Please provide a contact reference. This person can be a family member or a friend.
          </p>
          <div className="col-span-2  flex flex-col gap-[32px]">
            <div className="flex items-start mt-[8px]">
              <CheckboxInput
                large
                label=""
                className="mt-[0px] mb-[0px] flex items-center min-h-[33px]"
                value={referenceDetails.agreeToPolicy}
                error={errors.agreeToPolicy}
                onChange={handleValueChange("agreeToPolicy")}
                id="test-reference-policy"
                disabled={isSubmitting}
              >
                <div className="caption">
                  I agree to Finabanx's{" "}
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      const url = "https://icash.ca/privacy-policy"
                      if (!isApp) {
                        window.open(url, "_blank")
                      } else {
                        postMessageSender({
                          event: "display-website",
                          payload: { url: url },
                        })
                      }
                    }}
                    className="underline cursor-pointer inline-block"
                  >
                    Privacy Policy
                  </p>{" "}
                  and{" "}
                  <p
                    onClick={(e) => {
                      e.stopPropagation()
                      const url = "https://icash.ca/terms-conditions"
                      if (!isApp) {
                        window.open(url, "_blank")
                      } else {
                        postMessageSender({
                          event: "display-website",
                          payload: { url: url },
                        })
                      }
                    }}
                    className="underline cursor-pointer inline-block"
                  >
                    Terms of Use and Conditions
                  </p>
                </div>
              </CheckboxInput>
            </div>
            <div className="flex items-start mt-[8px]">
              <CheckboxInput
                large
                label=""
                className="mt-[0px] mb-[0px]"
                value={referenceDetails.agreeToAccurateInfo}
                error={errors.agreeToAccurateInfo}
                onChange={handleValueChange("agreeToAccurateInfo")}
                id="test-reference-info"
                disabled={isSubmitting}
              >
                <p className="caption">
                  I confirm that I provided accurate information about my financial situation and I will manage my
                  future income and expenses in order to respect the terms of my loan.
                </p>
              </CheckboxInput>
            </div>
            {!marketingConsent && (
              <div className="flex items-start mt-[8px]">
                <CheckboxInput
                  large
                  label=""
                  value={marketingConsentUpdated}
                  onChange={(value) => {
                    dispatch({
                      step: "marketingConsentUpdated",
                      payload: {
                        marketingConsentUpdated: value ?? false
                      }
                    })
                  }}
                  className="mt-[0px] mb-[0px]"
                  id="test-reference-promotion"
                  disabled={isSubmitting}
                >
                  <p className="caption">
                    I would like to receive promotional content to stay updated with Finabanx's new products and offers.
                  </p>
                </CheckboxInput>
              </div>
            )}
            <div className="flex items-start mt-[8px]">
              <CheckboxInput
                large
                label=""
                className="mt-[0px] mb-[0px]"
                value={referenceDetails.agreeToShareInfo}
                error={errors.agreeToShareInfo}
                onChange={handleValueChange("agreeToShareInfo")}
                id="test-reference-share"
                disabled={isSubmitting}
              >
                <p className="caption">
                  I agree that Finabanx can use my personal information to validate my identity and to obtain my credit
                  report*.
                  <br />
                  <span className="caption !text-[10px] text-[#888D91]">
                    * A one-time verification does not affect your credit score. In addition, returning customers who
                    reapply after a short period of time are exempt from this verification
                  </span>
                </p>
              </CheckboxInput>
            </div>
          </div>
        </div>
        <div className="flex sm:space-x-0 flex-col">
          <p className="caption mb-[32px] sm:mb-[24px]">
            By clicking Continue, I confirm that the information provided is current and accurate.
          </p>
          <div className="flex space-x-[22px] sm:flex-col-reverse sm:space-x-0">
            <Button
              size="large"
              className="flex-1 sm:mt-[22px] sm:py-[13px] sm:text-[16px] sm:hidden"
              onClick={() => goToPrevious(navigate, path)}
              disabled={isSubmitting}
            >
              Previous step
            </Button>
            <Button
              size="large"
              className="flex-1 sm:py-[13px] sm:text-[16px]"
              appearance="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!!Object.values(errors).join("") || !getAgreed() || isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}
