import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import "@brainfinance/icash-component-library/dist/index.css"
import "./index.css"
import App from "./Components/App"
import reportWebVitals from "./reportWebVitals"
import { ToastContainer, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import xThin from "./images/ic-x-gray.svg"

ReactDOM.render(
  <BrowserRouter>
    <App />
    <ToastContainer
      position="top-center"
      className="global--toast"
      bodyClassName="global--toast-body"
      hideProgressBar={true}
      transition={Zoom}
      closeButton={({ closeToast }) => (
        <button onClick={closeToast} className="flex-shrink-0 self-start">
          <img src={xThin} />
        </button>
      )}
    ></ToastContainer>
  </BrowserRouter>,
  document.getElementById("root")
)

//@ts-ignore
// debug for android
// document.body.append(navigator.userAgent + ' ' + window.Android + ' ' + /WebView|wv|^http.agent$/i.test(navigator.userAgent))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
