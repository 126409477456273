import { useContext, useEffect, useMemo, useRef, useState } from "react"

import { SimpleProgressBar } from "@brainfinance/icash-component-library"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { getLoanStatus } from "../../../Helpers/Requests"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { goToNext } from "../../../Helpers/Routes"
import { useNavigate, useLocation, Location } from "react-router-dom"
import { MessagesContext } from "../../../Helpers/Reducers/MessagesData"

type LocationWithState = Omit<Location, 'state'> & { state: { [key: string]: any } };

const MAXIMUM = 120

function randomIndex(pickedIndices: number[]): number | undefined {
  if (pickedIndices.length === 0) return

  const internalIndex = Math.floor(Math.random() * pickedIndices.length)
  const index = pickedIndices.splice(internalIndex, 1)

  return index[0]
}

function useDynamicMessages({ messages = [], period = 10 }: { messages: string[]; period: number }) {
  const pickedIndexes = useRef<number[]>([])

  const [message, setMessage] = useState(() => {
    if (messages.length > 0) {
      messages.forEach((_, index) => {
        if (index === 0) return

        pickedIndexes.current.push(index)
      })

      return messages[0]
    }

    return `We’re reviewing your application...`
  })

  function updateMessage(timeInSec: number) {
    if (!messages || messages.length === 0 || period === undefined || period === 0 || timeInSec % period !== 0) {
      return
    }

    const index = randomIndex(pickedIndexes.current)

    if (index === undefined || index > messages.length - 1) {
      return
    }

    setMessage(messages[index])
  }

  return {
    message,
    updateMessage,
  }
}

export function ReviewInProgress() {
  const {
    state: { token },
  } = useContext(RequestsContext)
  const {
    state: { applicationReviewMessages },
  } = useContext(MessagesContext)
  const navigate = useNavigate()
  const { pathname, state } = useLocation() as LocationWithState;
  const now = useRef<number>(Date.now())

  const { message, updateMessage } = useDynamicMessages({
    messages: applicationReviewMessages,
    period: 10,
  })

  const [timerEnd, setTimerEnds] = useState(false)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if (state.visited) {
      postMessageSender({
        event: "return"
      })

      return;
    }

    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev === MAXIMUM) {
          return prev
        }

        const diffInSec = Math.trunc((Date.now() - now.current) / 1000)

        return diffInSec >= MAXIMUM ? MAXIMUM : diffInSec
      })
    }, 1000)

    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_review_in_progress_loaded",
        },
      },
    })
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (seconds === MAXIMUM) {
      setTimerEnds(true)
    }

    updateMessage(seconds)
  }, [seconds])

  useEffect(() => {
    const interval = setInterval(() => {
      getLoanStatus(token).then((instantLoan) => {
        if (instantLoan?.status === "pendingSignature") {
          //send message for going to contract-signature
          postMessageSender({
            event: "goToApplicationContract",
          })
          try {
            clearInterval(interval)
          } catch (error) { }
        }
      })
    }, 5000)
    return () => {
      try {
        clearInterval(interval)
      } catch (error) { }
    }
  }, [token])

  useEffect(() => {
    if (timerEnd) {
      getLoanStatus(token).then((instantLoan) => {
        if (instantLoan?.status === "pendingSignature") {
          //send message for going to contract-signature
          postMessageSender({
            event: "goToApplicationContract",
          })
        } else {
          navigate(location.pathname, { state: { ...state, visited: true }, replace: true });
          goToNext(navigate, pathname.slice(1))
        }
      })
    }
  }, [timerEnd, token, pathname, navigate])

  const timerFormated = useMemo(() => {
    const secondsReverted = MAXIMUM - seconds
    const minuteTmp = Math.floor(secondsReverted / 60)
    const secondsTmp = secondsReverted % 60

    return `${minuteTmp >= 10 ? minuteTmp : `0${minuteTmp}`}:${secondsTmp >= 10 ? secondsTmp : `0${secondsTmp}`}`
  }, [seconds])

  return (
    <div className="max-w-[622px]">
      <p className="caption-light-no-color text-[#13141A] mb-[22px]">
        Congratulations! You're one step away from being approved for your loan.
      </p>
      <div className="flex flex-row items-center gap-2">
        <SimpleProgressBar completed={seconds} goal={MAXIMUM} />
        <div className="w-[120px]">
          <p className="main-title-caption !text-primary">{timerFormated}</p>
        </div>
      </div>
      <p className="caption-medium-no-colors text-[#13141A] mt-[16px]">{message}</p>
    </div>
  )
}
