import { useContext, useState, useRef, useEffect, useMemo } from "react"
import { useLocation, Outlet } from "react-router-dom"
import {
  Price,
  SummaryBox,
  SummaryDivider,
  LabeledValue,
  Button,
  SidePanel,
} from "@brainfinance/icash-component-library"
import { Header } from "../../Utilities/Header"
import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { ModifyLoanParameters } from "../../Steps"

import BackgroundImage from "../../../images/symbol-instant-loans-half-left.svg"
import { InstantLoanStateType } from "../../../Helpers/Reducers/types"
import { postMessageSender } from "../../../Helpers/PostMessage"
import SupportImage from "../../../images/support.png"
import infoIcon from "../../../images/info.svg"
import { Popup } from "../../Utilities/Popup"
import ResizeObserver from "resize-observer-polyfill"
import { isSameRoute } from "../Header/helpers"

const BANKING_DETAILS_ROUTES = [
  "/banking-details",
  "/banking-details/search",
  /banking-details\/(plaid|flinks)\/bank\/w*/i,
]

const HIDE_LOAN_DETAILS_ROUTES = ["/", "/loan-preapproved", ...BANKING_DETAILS_ROUTES, "/review-in-progress"]

const SHOW_MODIFY_LOAN_INFO_ROUTES = [
  "/personal-details",
  "/residential-details",
  "/employment-details",
  "/income-details",
  "/financial-details",
  ...BANKING_DETAILS_ROUTES,
  "/fast-track/review",
]

const SHOW_DEFAULT_LOAN_INFO_ROUTES = ["/", "/mobile-verification", "/pin-verification"]

function includes(arr: (string | RegExp)[], comparable: string) {
  return arr.some((item) => isSameRoute(item, comparable))
}

function iOSVersion(userAgent: string) {
  const regex = /\b(?:iPhone|iPad|iPod)\b.+?\bOS (\d+)/.exec(userAgent)

  if (regex) {
    return parseInt(regex[1], 10)
  }

  // If iOS version information is not found, return null (or handle it according to your needs)
  return null
}

const IOS = iOSVersion(navigator.userAgent)

export function Layout() {
  const {
    state: { loanParameters, loading, provinces },
  } = useContext(InstantLoanContext)
  const { pathname } = useLocation()
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
  const iframeRef = useRef<HTMLDivElement | null>(null)
  const [ready, setReady] = useState(false)

  const [openMobileHelp, setOpenMobileHelp] = useState(false)

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((event) => {
        event.map((item) => {
          if (item.borderBoxSize && item.borderBoxSize.length > 0) {
            postMessageSender({
              event: "size",
              payload: {
                // NOTE: we add 5px to compensate strange scrollbar discrepancy
                height: Math.ceil(item.borderBoxSize[0].blockSize + 5),
              },
            })
          }
        })
      }),
    []
  )

  // prevent preliminary resizing - debounce by 500
  useEffect(() => {
    if (!iframeRef.current) return
    let el = iframeRef.current
    let timeout = setTimeout(() => (setReady(true), resizeObserver.observe(el)), 500)
    return () => {
      clearTimeout(timeout)
      resizeObserver.disconnect()
    }
  }, [iframeRef])

  return (
    <>
      <div id="scroll-anchor"></div>
      <div
        className={`max-w-[1138px] ${
          ready ? "min-h-full" : "max-h-screen overflow-hidden"
        } flex flex-col relative p-[32px] sm:p-0 ${IOS !== null && IOS < 15 ? `sm:mb-[80px]` : ``}`}
        ref={iframeRef}
      >
        {!includes(HIDE_LOAN_DETAILS_ROUTES, pathname) && (
          <div className=" sm:m-0 relative z-[1000] bg-white hidden sm:block mb-[33px]">
            <div className="bg-[#F7F7F7] px-[22px] py-[10px] flex items-center rounded-[18px] text-[13px] leading-[20px] tracking-[-0.25px] text-interface-500 sm:m-[8px]">
              <span className="text-[14px] tracking-[-0.3px] text-interface-300 font-500">Loan details:</span>
              {isRepaymentsProvince(loanParameters, provinces) ? (
                <>
                  <span className="font-600 text-[15px] ml-[11px]">${loanParameters.amount ?? 0}</span>
                  &nbsp;/&nbsp;{loanParameters.terms ?? 1}&nbsp;repayments
                </>
              ) : (
                <span className="font-600 text-[15px] ml-[11px]">${loanParameters.amount ?? 0}</span>
              )}
              <Button
                className="ml-auto bg-transparent border-0 text-primary"
                onClick={() => setIsSidePanelOpen(true)}
                size="small"
                appearance="alt"
              >
                Edit
              </Button>
            </div>
          </div>
        )}
        <div className="mb-[66px] sm:mb-[22px] sm:top-0 sm:left-0 sm:right-0 sm:z-20 sm:bg-[#ffffff] sm:fixed sm:!sticky">
          {includes(BANKING_DETAILS_ROUTES, pathname) && (
            <a className="absolute right-4 top-4 hidden sm:block" onClick={() => setOpenMobileHelp((v) => !v)}>
              <img src={infoIcon} alt="information" />
            </a>
          )}
          <Header />
        </div>
        <div className="flex items-start gap-x-[88px] md:flex-col md:gap-y-[66px] md:items-center sm:gap-y-[16.5px] sm:m-[16.5px] sm:mt-[0px]">
          <div className="w-[512px] sm:w-full">
            <Outlet />
          </div>
          {!loading && (
            <div className="flex flex-col gap-[22px] sm:w-full">
              {loanParameters.province && (
                <div
                  className={`mx-auto sm:w-full ${
                    includes(HIDE_LOAN_DETAILS_ROUTES, pathname) ? "sm:max-w-sm xs:max-w-full" : "sm:hidden"
                  }`}
                >
                  {pathname !== "/review-in-progress" && pathname !== "/loan-preapproved" && (
                    <LoanDetails
                      loanParameters={loanParameters}
                      pathname={pathname}
                      provinces={provinces}
                      onModifyLoanClick={() => setIsSidePanelOpen(true)}
                    />
                  )}
                </div>
              )}
              {includes(BANKING_DETAILS_ROUTES, pathname) && <HelpBox className="sm:hidden" />}
            </div>
          )}
          {pathname !== "/" && (
            <SidePanel
              className="!w-1/2 lg:!w-1/2 sm:!w-full !pt-[88px] overflow-x-hidden"
              active={isSidePanelOpen}
              onClose={() => setIsSidePanelOpen(false)}
            >
              <div className="flex flex-col items-center sm:w-[90vw]">
                <h2 className="title-caption mb-[30px]">Modify loan info</h2>
                <ModifyLoanParameters onSubmit={() => setIsSidePanelOpen(false)} />
              </div>
            </SidePanel>
          )}
          {pathname === "/loan-preapproved" && (
            <Button
              size="large"
              className="sm:block hidden mt-[33px] sm:mt-[22px] sm:w-full md:text-[16px] md:py-[10px]"
              appearance="primary"
              onClick={() => postMessageSender({ event: "finished" })}
            >
              Back to Home
            </Button>
          )}
        </div>

        {openMobileHelp && (
          <Popup classNamesContainer="" classNamesPopup="!w-auto !mx-[16px]" onClose={() => setOpenMobileHelp(false)}>
            <HelpBox />
          </Popup>
        )}
      </div>
    </>
  )
}

type LoanDetailsProps = {
  pathname: string
  loanParameters: any
  provinces: InstantLoanStateType["provinces"]
  onModifyLoanClick: () => void
}

const LoanDetails = ({ pathname, loanParameters, provinces, onModifyLoanClick }: LoanDetailsProps) => {
  return (
    <SummaryBox
      className={includes(BANKING_DETAILS_ROUTES, pathname) ? "sm:hidden" : ""}
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: isRepaymentsProvince(loanParameters, provinces) ? "198px 53px" : "230px 53px",
        backgroundRepeat: "no-repeat",
        backgroundSize: isRepaymentsProvince(loanParameters, provinces) ? "190px auto" : "170px auto",
      }}
    >
      <h2 className="title-caption mb-[32px]">Loan details</h2>
      <div className="w-[160px] mb-[10px]">
        <LabeledValue label="Loan amount" className="test-side-total">
          <Price integer amount={loanParameters.amount ?? 0} size="medium" />
        </LabeledValue>
        <br />
        {includes(SHOW_DEFAULT_LOAN_INFO_ROUTES, pathname) && isRepaymentsProvince(loanParameters, provinces) && (
          <LabeledValue label="Repayments" className="test-repayments-step1">
            {loanParameters.terms ?? 1}
          </LabeledValue>
        )}
      </div>
      {includes(SHOW_DEFAULT_LOAN_INFO_ROUTES, pathname) && (
        <>
          {isRepaymentsProvince(loanParameters, provinces) && <SummaryDivider />}
          <LabeledValue label="Total amount to be repaid">
            <Price className="mb-[8px]" amount={totalAmount(loanParameters)} size="medium" />
          </LabeledValue>
        </>
      )}
      {includes(SHOW_MODIFY_LOAN_INFO_ROUTES, pathname) &&
        isRepaymentsProvince(loanParameters, provinces) &&
        !isNaN(totalAmount(loanParameters)) && (
          <LabeledValue
            className="whitespace-pre-line w-40 test-each-repayments-price"
            label={`Each of the ${loanParameters.terms ?? 1} payment${
              (loanParameters.terms ?? 1) > 1 ? "s" : ""
            } will be`}
          >
            <Price className="mb-[8px]" amount={amountPerPayment(loanParameters)} size="medium" />
          </LabeledValue>
        )}
      {includes(SHOW_MODIFY_LOAN_INFO_ROUTES, pathname) && (
        <Button className="mt-[20px]" size="small" appearance="tertiary" onClick={onModifyLoanClick}>
          Modify loan info
        </Button>
      )}
    </SummaryBox>
  )
}

const isRepaymentsProvince = (
  loanParameters: InstantLoanStateType["loanParameters"],
  provinces: InstantLoanStateType["provinces"]
) => {
  const provinceSelected = provinces.find((tmp) => tmp.code === loanParameters.province)
  if (provinceSelected && provinceSelected.maxTerms > 1) {
    return true
  }
  return false
}

const totalAmount = (loanParameters: InstantLoanStateType["loanParameters"]) => {
  return (loanParameters.amount || 0) + (loanParameters.cost || 0) || 0
}

const amountPerPayment = (loanParameters: InstantLoanStateType["loanParameters"]) => {
  return totalAmount(loanParameters) / Math.max(loanParameters.terms || 0, 1) || 0
}

export const HelpBox = ({ className }: { className?: string }) => {
  return (
    <SummaryBox className={"text-center mx-auto " + className}>
      <img className="mx-auto rounded-full w-28 h-28 mb-[22px]" src={SupportImage} alt="iCash Support" />
      <h3 className="subtitle-caption mb-[22px]">
        Need help?
        <br />
        Call us at{" "}
        <a className="!text-primary" href="tel:1-604-283-3349">
          1-604-283-3349
        </a>
      </h3>
    </SummaryBox>
  )
}
