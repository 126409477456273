import { useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Button, PinInput } from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import {
  getStatusFastTrack,
  postValidatePhone,
  postValidatePhoneCompletion,
} from "../../../Helpers/Requests"

import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function PinVerification() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [valid, setValid] = useState<boolean>()

  const navigate = useNavigate()

  const {
    state: { mobileVerification },
  } = useContext(InstantLoanContext)

  const {
    state: { token },
  } = useContext(RequestsContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  const verifyCode = (pin: number) => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_phone_validation_pin_submitted",
        },
      },
    })

    setIsSubmitting(true)
    postValidatePhoneCompletion(mobileVerification, pin, token)
      .then(() => setValid(true))
      .catch((e) => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_phone_validation_pin_error",
              properties: {
                phoneNumber: mobileVerification.mobileNumber,
                error: e.message ?? "",
              },
            },
          },
        })
        setValid(false)
        setIsSubmitting(false)
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }

  const onValid = async () => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_phone_validation_pin_completed",
        },
      },
    })

    if (token) {
      const res = await getStatusFastTrack(token)
      if (res.data?.isFastTrackAvailable === true && (res.data?.fastTrackWanted ?? true) === true) {
        navigate("/fast-track/review", {
          state: {
            title: "Review and complete",
          },
        })
        return
      }
    }
    goToNext(navigate, path)
  }

  const onResendSMS = () => {
    postValidatePhone(mobileVerification, token)
      .then(() => toast("A sms has been sent to you.", { type: "info" }))
      .catch((e) => toast(e.message || e, { type: "error" }))
  }

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_phone_validation_pin_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={!token}>
      <div className="w-[529px] sm:w-full">
        <p className="caption-light mb-[32px]">{`Enter the verification code we sent to ${mobileVerification.mobileNumber}.`}</p>
        <PinInput
          valid={valid}
          validate={verifyCode}
          onValid={onValid}
          onReset={() => {
            setValid(undefined)
          }}
        />
        <div className="my-[32px]">
          <p className="caption-medium-no-colors">
            <span className="text-primary cursor-pointer" onClick={onResendSMS}>
              Click here
            </span>{" "}
            to receive a new code or{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => postMessageSender({ event: "contact-us" })}
            >
              contact
            </span>{" "}
            our support if you need help.
          </p>
        </div>
        <div className="flex space-x-[22px]">
          <Button
            size="large"
            className="flex-1 sm:py-[10px] sm:text-[16px]"
            onClick={() => goToPrevious(navigate, path)}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Previous step
          </Button>
        </div>
      </div>
    </Loader>
  )
}
