const convertionStateDict: { [key: number]: string } = {
  1: "QC",
  2: "BC",
  3: "ON",
  4: "NS",
  5: "NB",
  6: "MB",
  7: "AB",
  8: "PE",
  9: "SK",
  10: "NL",
  11: "NU",
  12: "NT",
  13: "YT",
}

export const findProvinceCodeById = (provinceId: number) => {
  const provinceCode: string | undefined = convertionStateDict[provinceId]
  return provinceCode
}

export const loanSettingsRestrictions: {
  [key: string]: {
    informationMessage: () => JSX.Element
  }
} = {
  AB: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <p className="caption-light mt-[16px] mb-[16px]">
          Once your application is approved, the system will automatically provide the number of
          repayments based on your pay frequency - ranging between 42 to 62 days.
        </p>
        <div className="caption-light mt-[16px]">
          <p className="mt-[8px]">6 to 7 repayments - Paid weekly</p>
          <p className="mt-[8px]">3 to 4 repayments - Paid bi-weekly or twice a month</p>
          <p className="mt-[8px]">2 to 3 repayments - Paid monthly</p>
        </div>
      </div>
    ),
  },
  BC: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <div className="">
          <h3 className="subtitle-caption mt-[24px]">2 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            2 repayment loans are only available for customers who get paid weekly, bi-weekly, twice
            a month or receive 2 monthly sources of government income.
          </p>
          <h3 className="subtitle-caption mt-[24px]">3 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            3 repayment loans are only available for customers who get paid weekly, bi-weekly or
            twice a month from the same income.
          </p>
        </div>
      </div>
    ),
  },
  ON: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <p className="caption-light mt-[16px] mb-[16px]">
          You can qualify for 2 or 3 repayments if it is your third loan in a 63 day period. If you
          qualify, the system will automatically provide you with this option once your loan
          application is finished.
        </p>
      </div>
    ),
  },
  NB: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <div className="">
          <h3 className="subtitle-caption mt-[24px]">2 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            2 repayment loans are only available for customers who get paid weekly, bi-weekly, twice
            a month or receive 2 monthly sources of government income.
          </p>
          <h3 className="subtitle-caption mt-[24px]">3 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            3 repayment loans are only available for customers who get paid weekly, bi-weekly or
            twice a month from the same income.
          </p>
        </div>
      </div>
    ),
  },
  MB: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <div className="">
          <h3 className="subtitle-caption mt-[24px]">2 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            2 repayment loans are only available for customers who get paid weekly, bi-weekly, twice
            a month or receive 2 monthly sources of government income.
          </p>
          <h3 className="subtitle-caption mt-[24px]">3 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            3 repayment loans are only available for customers who get paid weekly, bi-weekly or
            twice a month from the same income.
          </p>
        </div>
      </div>
    ),
  },
  NS: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <p className="caption-light mt-[16px] mb-[16px]">
          In order to comply with Nova Scotia regulations we cannot offer the 2 or 3 repayment
          options in your province.
        </p>
      </div>
    ),
  },
  PE: {
    informationMessage: () => (
      <div>
        <h2 className="title-caption">Repayment options</h2>
        <div className="">
          <h3 className="subtitle-caption mt-[24px]">2 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            2 repayment loans are only available for customers who get paid weekly, bi-weekly, twice
            a month or receive 2 monthly sources of government income.
          </p>
          <h3 className="subtitle-caption mt-[24px]">3 repayments:</h3>
          <p className="caption-light mt-[16px] mb-[16px]">
            3 repayment loans are only available for customers who get paid weekly, bi-weekly or
            twice a month from the same income.
          </p>
        </div>
      </div>
    ),
  },
  SK: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
  QC: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
  NL: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
  YT: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
  NT: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
  NU: {
    informationMessage: () => <div className="subtitle-caption">Not Applicable</div>,
  },
}
