import { PropsWithChildren } from "react"
import "./style.css"
import closeIcon from "../../../images/ic-x-black.svg"

type PopupProps = PropsWithChildren<{
  onCancel?: () => void
  onConfirm?: () => void
  onClose?: () => void
  classNamesPopup?: string
  classNamesContainer?: string
}>

export function Popup(props: PopupProps) {
  return (
    <div
      className={`popup-container ${props.classNamesContainer ? props.classNamesContainer : ""}`}
      onClick={props.onClose}
    >
      <div
        className={`popup ${props.classNamesPopup ? props.classNamesPopup : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <img className="popup-close" src={closeIcon} alt="Close" onClick={props.onClose} />
        {props.children}
        {props.onCancel || props.onConfirm ? (
          <div className="popup-button-container">
            {props.onCancel && <button onClick={props.onCancel}>Cancel</button>}
            {props.onConfirm && <button onClick={props.onConfirm}>Confirm</button>}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
