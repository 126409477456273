import { useContext, useState, useEffect, useMemo } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Button,
  DateInput,
  InputGroup,
  SelectInput,
  TextInput,
  ValidationMessage,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { listParser } from "../../../Helpers/Parsers/List"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { UserContext } from "../../../Helpers/Reducers/User"
import { incomeDetailsParser } from "../../../Helpers/Parsers/Steps/IncomeDetails"

import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"

import { toast } from "react-toastify"

import { incomeDetailsValidators, isSocialIncomeCode, isSocialAdditionIncomeCode } from "../../../Helpers/Validators/Steps/IncomeDetails"
import { validateValues } from "../../../Helpers/Validators"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function IncomeDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  let [hasAdditionalIncome, setHasAdditionalIncome] = useState<"Yes" | "No">("No")
  const [lists, setLists] = useState({
    bankAccountDuration: [],
    paycheckMethod: [],
    incomeType: [],
    additionalIncomeFrequency: [],
    joinBankAccounts: [],
    additionalIncomeSource: [],
    bankEtransfer: [],
    paycheckFrequency: [],
  })

  const navigate = useNavigate()

  const MIN_DATE = new Date(new Date().setHours(0, 0, 0, 0))

  const {
    state: { incomeDetails, isApp },
    dispatch,
  } = useContext(InstantLoanContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const {
    state: { user },
  } = useContext(UserContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  // cleanup details for progress bar / submit
  const cleanupDetails = (details: typeof incomeDetails) => {
    let key: keyof typeof details
    for (key in details) if (details[key] === "0") (details[key] as string) = ""
    details.additionalIncomeSource ||= ""
    details.additionalIncomeAmount ||= ""
    details.additionalIncomeFrequency ||= ""

    // delete unnecessary fields
    if (hasAdditionalIncome !== "Yes") {
      details.additionalIncomeSource = undefined
      details.additionalIncomeAmount = undefined
      details.additionalIncomeFrequency = undefined
    }

    if (isSocialIncomeCode(details.employmentStatus)) {
      details.paymentSchedule = '7'
      details.paymentMethod = '1'
      details.additionalIncomeFrequency = undefined
    }

    if (isSocialAdditionIncomeCode(details.additionalIncomeSource)) {
      details.additionalIncomeFrequency = '7'
    }

    return details
  }

  const handleValueChange =
    <T extends string | number | Date>(key: keyof typeof incomeDetails) =>
      (value?: T) => {
        setErrors({})
        dispatch({
          step: "incomeDetails",
          payload: cleanupDetails({ ...incomeDetails, [key]: value }),
        })
      }

  const handleSubmit = () => {
    let incomeDetailsValues: Partial<Record<keyof ReturnType<typeof cleanupDetails>, any>> = incomeDetailsParser(cleanupDetails(incomeDetails))

    if (isSocialIncome) {
      incomeDetailsValues.nextPayDate = '';
      incomeDetailsValues.lastPayDate = '';
    }

    // validate details
    let validationErrors = validateValues(incomeDetails, incomeDetailsValidators as {})
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () => document.querySelector(".global--input-error")?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_salary_information_submitted",
        },
      },
    })

    setIsSubmitting(true)
    postStepRequest(4, incomeDetailsValues, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_salary_information_completed",
            },
          },
        })
        goToNext(navigate, path)
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsSubmitting(false)
      })
  }

  const isSocialIncome = useMemo(() => {
    return isSocialIncomeCode(incomeDetails.employmentStatus);
  }, [incomeDetails.employmentStatus])

  const isSocialAdditionIncome = useMemo(() => {
    return isSocialAdditionIncomeCode(incomeDetails.additionalIncomeSource);
  }, [incomeDetails.additionalIncomeSource])

  const createDate = (dateStr: string) => {
    const date = new Date(dateStr)
    if (date.toString() === "Invalid Date") {
      return new Date()
    }
    return date
  }

  const createNextPayDate = (dateStr: string): Date | undefined => {
    const date = createDate(dateStr)
    if (date < MIN_DATE) {
      return new Date()
    }
    return date
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getStepRequest(4, token)
      .then(({ data, list }) => {
        for (let listName in list) list[listName] = listParser(list[listName])
        setLists(list)

        setHasAdditionalIncome(
          (hasAdditionalIncome = Number(data.additionalIncomeSource) ? "Yes" : "No")
        )
        dispatch({
          step: "incomeDetails",
          payload: cleanupDetails({
            ...data,
            lastPayDate: data.lastPayDate ? createDate(data.lastPayDate + "T00:00:00") : new Date(),
            nextPayDate: data.nextPayDate ? createNextPayDate(data.nextPayDate + "T00:00:00") : new Date(),
          }),
        })
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_salary_information_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        <div className="grid grid-cols-2 gap-x-[22px] mb-[22px] items-start sm:flex sm:flex-col sm:items-stretch">
          {
            !isSocialIncome ?
              <>
                <SelectInput
                  native={isApp ? false : undefined}
                  label="How do you get paid?"
                  value={incomeDetails.paymentMethod}
                  error={errors.paymentMethod}
                  onChange={handleValueChange("paymentMethod")}
                  options={lists.paycheckMethod}
                  id="test-payment-method"
                  disabled={isSubmitting}
                />
                <SelectInput
                  native={isApp ? false : undefined}
                  label="How often do you get paid?"
                  value={incomeDetails.paymentSchedule}
                  error={errors.paymentSchedule}
                  onChange={handleValueChange("paymentSchedule")}
                  options={lists.paycheckFrequency}
                  id="test-payment-schedule"
                  disabled={isSubmitting}
                />
              </>
              : null
          }
          <TextInput
            className="sm:mt-0 mt-auto"
            label="Last net pay amount"
            inputMode="decimal"
            fieldInfo={
              <div className="w-[200px] global--input-label font-400 text-[10px]">
                The total amount of your last paycheque after deductions such as taxes, Canadian Pension Plan, and Employment Insurance contributions were removed. Your net pay is the amount
                deposited into your bank account.
              </div>
            }
            value={incomeDetails.incomeAmount}
            error={errors.incomeAmount}
            onChange={handleValueChange("incomeAmount")}
            id="test-income-amount"
            disabled={isSubmitting}
          />
          <SelectInput
            native={isApp ? false : undefined}
            label="Do you receive your pay in a joint bank account?"
            value={incomeDetails.joinBankAccount}
            error={errors.joinBankAccount}
            onChange={handleValueChange("joinBankAccount")}
            options={lists.joinBankAccounts}
            id="test-join-bank-account"
            disabled={isSubmitting}
          />
          {
            !isSocialIncome ? (<>
              <DateInput
                label="Last pay day"
                value={incomeDetails.lastPayDate}
                error={errors.lastPayDate}
                onChange={handleValueChange("lastPayDate")}
                maxDate={MIN_DATE}
                id="test-last-pay-day"
                disabled={isSubmitting}
              />
              <DateInput
                label="Next pay day"
                value={incomeDetails.nextPayDate}
                error={errors.nextPayDate}
                minDate={MIN_DATE}
                onChange={handleValueChange("nextPayDate")}
                id="test-next-pay-day"
                disabled={isSubmitting}
              />
            </>) : null
          }
          <InputGroup className="col-span-2 mb-[22px] flex flex-col py-4">
            <SelectInput
              native={isApp ? false : undefined}
              label="Are you able to receive e-Transfers with the same email used for your iCash account?"
              value={incomeDetails.bankEtransfer}
              error={errors.bankEtransfer}
              onChange={handleValueChange("bankEtransfer")}
              options={lists.bankEtransfer}
              id="test-bank-etransfer"
              disabled={isSubmitting}
            />
            {incomeDetails.bankEtransfer === "1" ? (
              <ValidationMessage status="info">
                <p className="caption-medium">
                  Your funds will be sent via e-Transfer to the email address used for your iCash
                  account. If you wish to change that, please contact us BEFORE you sign your
                  contract. Also, please be sure your email address is not set up for auto-deposit
                  to a closed bank account.
                </p>
              </ValidationMessage>
            ) : (
              <ValidationMessage status="info">
                Note that if your loan is approved, your funds will be sent by direct deposit.
              </ValidationMessage>
            )}
          </InputGroup>
          {hasAdditionalIncome === "Yes" ? (
            <InputGroup className="col-span-2 grid grid-cols-2 sm:grid-cols-1 gap-x-[22px] pt-4 items-start">
              <SelectInput
                native={isApp ? false : undefined}
                label="Do you have additional income?"
                value={hasAdditionalIncome}
                onChange={(value) => (
                  setErrors({}),
                  setHasAdditionalIncome((hasAdditionalIncome = value === "Yes" ? "Yes" : "No")),
                  dispatch({ step: "incomeDetails", payload: cleanupDetails({ ...incomeDetails }) })
                )}
                options={[
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ]}
                id="test-has-additional-income"
                disabled={isSubmitting}
              />
              <div />
              <SelectInput
                native={isApp ? false : undefined}
                label="What is your additional income source?"
                value={incomeDetails.additionalIncomeSource}
                error={errors.additionalIncomeSource}
                onChange={handleValueChange("additionalIncomeSource")}
                options={lists.additionalIncomeSource}
                id="test-income-source"
                disabled={isSubmitting}
              />
              {incomeDetails.additionalIncomeSource ? (
                <>
                  <TextInput
                    label="Amount of your additional income"
                    className="sm:mt-0 self-end"
                    inputMode="decimal"
                    value={incomeDetails.additionalIncomeAmount}
                    error={errors.additionalIncomeAmount}
                    onChange={handleValueChange("additionalIncomeAmount")}
                    id="test-additional-income-amount"
                    disabled={isSubmitting}
                  />
                  {
                    !isSocialAdditionIncome ?
                      <SelectInput
                        native={isApp ? false : undefined}
                        label="Frequency of additional income"
                        value={incomeDetails.additionalIncomeFrequency}
                        error={errors.additionalIncomeFrequency}
                        onChange={handleValueChange("additionalIncomeFrequency")}
                        options={lists.paycheckFrequency}
                        id="test-income-frequency"
                        disabled={isSubmitting}
                      />: <></>
                  }
                </>
              ) : (
                <div />
              )}
            </InputGroup>
          ) : (
            <SelectInput
              native={isApp ? false : undefined}
              label="Do you have additional income?"
              value={hasAdditionalIncome}
              onChange={(value) => (
                setErrors({}),
                setHasAdditionalIncome((hasAdditionalIncome = value === "Yes" ? "Yes" : "No")),
                dispatch({ step: "incomeDetails", payload: cleanupDetails({ ...incomeDetails }) })
              )}
              options={[
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              id="test-has-additional-income"
              disabled={isSubmitting}
            />
          )}
          <div />
        </div>
        <div className="flex sm:space-x-0 flex-col">
          <p className="caption mb-[32px] sm:mb-[24px]">
            By clicking Continue, I confirm that the information provided is current and accurate.
          </p>
          <div className="flex space-x-[22px] sm:flex-col-reverse sm:space-x-0">
            <Button
              size="large"
              className="flex-1 sm:mt-[22px] sm:py-[13px] sm:text-[16px] sm:hidden"
              onClick={() => goToPrevious(navigate, path)}
              disabled={isSubmitting}
            >
              Previous step
            </Button>
            <Button
              size="large"
              className="flex-1 sm:py-[13px] sm:text-[16px]"
              appearance="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!!Object.values(errors).join("") || isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}
