import { DashboardSkeleton } from "@brainfinance/icash-component-library"
import { PropsWithChildren, Fragment } from "react"

type LoaderProps = PropsWithChildren<{
  loading: boolean
  extraPadding?: boolean
}>

export function Loader(props: LoaderProps) {
  if (props.loading)
    return (
      <div
        className={`h-full w-full p-[32px] ${props.extraPadding ? "sm:p-[22px]" : "sm:p-[6px]"}`}
      >
        {/* Preload font when fetching data. */}
        <div style={{ opacity: 0 }}>
          <span style={{ fontFamily: "Poppins" }} />
          <span style={{ fontFamily: "Poppins", fontWeight: 600 }} />
          <span style={{ fontFamily: "Poppins", fontWeight: 500 }} />
          <span style={{ fontFamily: "Poppins", fontWeight: 400 }} />
          <span style={{ fontFamily: "Poppins", fontWeight: 300 }} />
        </div>
        <DashboardSkeleton />
      </div>
    )

  return <Fragment>{props.children}</Fragment>
}
