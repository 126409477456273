import { createContext, Dispatch, useReducer } from "react"
import type { InstantLoanStateType, InstantLoanActionType } from "../types"

import { calculateCost } from "../LoanParameters"

const instantLoanReducer = (state: InstantLoanStateType, action: InstantLoanActionType) => {
  switch (action.step) {
    case "loanParameters":
      let loanParameters = { ...state["loanParameters"], ...action.payload }
      const userProvinceFound = state.provinces.find((tmp) => tmp.code === loanParameters.province)
      loanParameters.cost = calculateCost(
        loanParameters.amount ?? 0,
        userProvinceFound?.interestRate
      )
      if (loanParameters.terms === undefined || loanParameters.terms === 0) {
        loanParameters.terms = 1
      }

      if (
        userProvinceFound?.maxTerms != null &&
        loanParameters.terms > userProvinceFound?.maxTerms
      ) {
        loanParameters.terms = userProvinceFound?.maxTerms
      }

      return { ...state, loanParameters }
    case "loading":
      return { ...state, loading: action.payload.loading }
    case "provinces":
      return { ...state, provinces: action.payload }
    case "aggregator":
      return { ...state, aggregator: action.payload.aggregator }
    case "marketingConsent":
      return { ...state, marketingConsent: action.payload.marketingConsent }
    case "marketingConsentUpdated":
      return { ...state, marketingConsentUpdated: action.payload.marketingConsentUpdated }
    case "homeStateChanged":
      let loanParametersTmp = {
        ...state["loanParameters"],
        province: action.payload.province,
      }
      const userProvince = state.provinces.find((tmp) => tmp.code === loanParametersTmp.province)

      loanParametersTmp.cost = calculateCost(
        loanParametersTmp.amount ?? 0,
        userProvince?.interestRate
      )
      if (loanParametersTmp.terms === undefined || loanParametersTmp.terms === 0) {
        loanParametersTmp.terms = 1
      }

      if (userProvince?.maxTerms != null && loanParametersTmp.terms > userProvince?.maxTerms) {
        loanParametersTmp.terms = userProvince?.maxTerms
      }

      return { ...state, loanParameters: loanParametersTmp }
    case "residentialDetails":
      let loanParamsUpdated = {
        ...state["loanParameters"],
      }

      if (action.payload.addressStateId && !isNaN(Number(action.payload.addressStateId))) {
        const provinceId = Number(action.payload.addressStateId)
        const provinceFound = state.provinces.find((tmp) => tmp.id === provinceId)
        if (provinceFound) {
          loanParamsUpdated.province = provinceFound.code

          if (loanParamsUpdated.terms === undefined || loanParamsUpdated.terms === 0) {
            loanParamsUpdated.terms = 1
          }

          if (
            provinceFound?.maxTerms != null &&
            loanParamsUpdated.terms > provinceFound?.maxTerms
          ) {
            loanParamsUpdated.terms = provinceFound?.maxTerms
          }
        }
      }
      return { ...state, residentialDetails: action.payload, loanParameters: loanParamsUpdated }
    case "isApp":
      return { ...state, isApp: action.payload.isApp }
    default:
      return {
        ...state,
        [action.step]: { ...state[action.step], ...action.payload },
      }
  }
}

const initialLoanReducerState: InstantLoanStateType = {
  employmentDetails: {},
  financialDetails: {},
  incomeDetails: {},
  aggregator: undefined,
  isApp: false,
  loanParameters: {
    amount: 500,
    cost: undefined,
    terms: 1,
  },
  provinces: [],
  mobileVerification: {},
  personalDetails: {},
  referenceDetails: {},
  residentialDetails: {},
}

export const useInstantLoanReducer = () => {
  return useReducer(instantLoanReducer, initialLoanReducerState)
}

export const InstantLoanContext = createContext<{
  state: InstantLoanStateType
  dispatch: Dispatch<InstantLoanActionType>
}>({ state: initialLoanReducerState, dispatch: (_action) => {} })
