import { InstantLoanRoutes } from "../../../Helpers/Routes"

export const getTitleFromLocationState = (state: any, pathname: string) => {
  if (typeof state === "object" && state && state.title) return state.title

  const routeInfo = InstantLoanRoutes.find((route) => isSameRoute(route.path, pathname.substring(1)))

  if (routeInfo?.parent) {
    const routeInfoParent = InstantLoanRoutes.find((route) => isSameRoute(route.path, routeInfo?.parent))

    return routeInfoParent?.state?.title
  }

  return routeInfo?.state?.title
}

export const getStepFromLocationState = (state: any, pathname: string) => {
  if (typeof state === "object" && state && state.step) return state.step

  const routeInfo = InstantLoanRoutes.find((route) => isSameRoute(route.path, pathname.substring(1)))

  if (routeInfo?.parent) {
    const routeInfoParent = InstantLoanRoutes.find((route) => isSameRoute(route.path, routeInfo?.parent))

    return routeInfoParent?.state?.step
  }

  return routeInfo?.state?.step
}

export const getStepPathFromLocationState = (state: any, pathname: string) => {
  if (typeof state === "object" && state && state.step) return pathname.substring(1)

  const routeInfo = InstantLoanRoutes.find((route) => isSameRoute(route.path, pathname.substring(1)))

  if (routeInfo?.parent) {
    const routeInfoParent = InstantLoanRoutes.find((route) => isSameRoute(route.path, routeInfo?.parent))

    return routeInfoParent?.path.toString() || pathname.substring(1)
  }

  return pathname.substring(1)
}

export const isSameRoute = (itemA: string | RegExp, itemB: string) => {
  const isRegExp = itemA instanceof RegExp

  return isRegExp ? itemA.test(itemB) : itemA === itemB
}
