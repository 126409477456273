import { useMemo } from "react"

import { useLocation } from "react-router-dom"
import { loanSettingsRestrictions } from "../../Helpers/Parsers/Steps/LoanParameters/loanParameters"

export function RepaymentOptions() {
  const location = useLocation()

  const loanSettingsRestrictionsInfo = useMemo(() => {
    const query = new URLSearchParams(location.search)

    const province = query.get("province")

    const provinceTmp = province ?? "AB"
    return loanSettingsRestrictions[provinceTmp]
  }, [location])

  return <div>{loanSettingsRestrictionsInfo?.informationMessage()}</div>
}
