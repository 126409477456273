// To parse this data:
//
//   import { Convert, ReviewType } from "./file";
//
//   const reviewType = Convert.toReviewType(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface ReviewType {
  addressDetails?: AddressDetails
  employmentStatusCovid?: EmploymentStatusCovid
  employmentDetails?: EmploymentDetails
  salaryDetails?: SalaryDetails
  bankAccountDetails?: BankAccountDetails
}

export interface AddressDetails {
  civicNumber?: string
  street?: string
  aptNumber?: string
  city?: string
  stateShortCode?: string
  postalCode?: string
}

export interface BankAccountDetails {
  etransferInfo?: EtransferInfo
  bankName?: string
  bankNumber?: string
  bankBranchNumber?: string
  bankAccountNumber?: string
  bankLogo?: string
}

export interface EtransferInfo {
  value?: string
  note?: string
}

export interface EmploymentDetails {
  employmentName?: string
  employmentStatus?: string
}

export interface EmploymentStatusCovid {
  covidAnswer?: string
}

export interface SalaryDetails {
  netPaycheck?: number
  paycheckFrequency?: string
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toReviewType(json: string): ReviewType {
    return cast(JSON.parse(json), r("ReviewType"))
  }

  public static reviewTypeToJson(value: ReviewType): string {
    return JSON.stringify(uncast(value, r("ReviewType")), null, 2)
  }
}

function invalidValue(typ: any, val: any, key: any = ""): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    )
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`)
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }))
    typ.jsonToJS = map
  }
  return typ.jsonToJS
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {}
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }))
    typ.jsToJSON = map
  }
  return typ.jsToJSON
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val
    return invalidValue(typ, val, key)
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length
    for (let i = 0; i < l; i++) {
      const typ = typs[i]
      try {
        return transform(val, typ, getProps)
      } catch (_) {}
    }
    return invalidValue(typs, val)
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val
    return invalidValue(cases, val)
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val)
    return val.map((el) => transform(el, typ, getProps))
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null
    }
    const d = new Date(val)
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val)
    }
    return d
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val)
    }
    const result: any = {}
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key]
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined
      result[prop.key] = transform(v, prop.typ, getProps, prop.key)
    })
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key)
      }
    })
    return result
  }

  if (typ === "any") return val
  if (typ === null) {
    if (val === null) return val
    return invalidValue(typ, val)
  }
  if (typ === false) return invalidValue(typ, val)
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref]
  }
  if (Array.isArray(typ)) return transformEnum(typ, val)
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val)
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val)
  return transformPrimitive(typ, val)
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps)
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps)
}

function a(typ: any) {
  return { arrayItems: typ }
}

function u(...typs: any[]) {
  return { unionMembers: typs }
}

function o(props: any[], additional: any) {
  return { props, additional }
}

function m(additional: any) {
  return { props: [], additional }
}

function r(name: string) {
  return { ref: name }
}

const typeMap: any = {
  ReviewType: o(
    [
      {
        json: "addressDetails",
        js: "addressDetails",
        typ: u(undefined, r("AddressDetails")),
      },
      {
        json: "employmentStatusCovid",
        js: "employmentStatusCovid",
        typ: u(undefined, r("EmploymentStatusCovid")),
      },
      {
        json: "employmentDetails",
        js: "employmentDetails",
        typ: u(undefined, r("EmploymentDetails")),
      },
      {
        json: "salaryDetails",
        js: "salaryDetails",
        typ: u(undefined, r("SalaryDetails")),
      },
      {
        json: "bankAccountDetails",
        js: "bankAccountDetails",
        typ: u(undefined, r("BankAccountDetails")),
      },
    ],
    false
  ),
  AddressDetails: o(
    [
      { json: "civicNumber", js: "civicNumber", typ: u(undefined, "") },
      { json: "street", js: "street", typ: u(undefined, "") },
      { json: "aptNumber", js: "aptNumber", typ: u(undefined, "") },
      { json: "city", js: "city", typ: u(undefined, "") },
      { json: "stateShortCode", js: "stateShortCode", typ: u(undefined, "") },
      { json: "postalCode", js: "postalCode", typ: u(undefined, "") },
    ],
    false
  ),
  BankAccountDetails: o(
    [
      {
        json: "etransferInfo",
        js: "etransferInfo",
        typ: u(undefined, r("EtransferInfo")),
      },
      { json: "bankName", js: "bankName", typ: u(undefined, "") },
      { json: "bankNumber", js: "bankNumber", typ: u(undefined, "") },
      {
        json: "bankBranchNumber",
        js: "bankBranchNumber",
        typ: u(undefined, ""),
      },
      {
        json: "bankAccountNumber",
        js: "bankAccountNumber",
        typ: u(undefined, ""),
      },
      { json: "bankLogo", js: "bankLogo", typ: u(undefined, "") },
    ],
    false
  ),
  EtransferInfo: o(
    [
      { json: "value", js: "value", typ: u(undefined, "") },
      { json: "note", js: "note", typ: u(undefined, "") },
    ],
    false
  ),
  EmploymentDetails: o(
    [
      { json: "employmentName", js: "employmentName", typ: u(undefined, "") },
      {
        json: "employmentStatus",
        js: "employmentStatus",
        typ: u(undefined, ""),
      },
    ],
    false
  ),
  EmploymentStatusCovid: o(
    [{ json: "covidAnswer", js: "covidAnswer", typ: u(undefined, "") }],
    false
  ),
  SalaryDetails: o(
    [
      { json: "netPaycheck", js: "netPaycheck", typ: u(undefined, 0) },
      {
        json: "paycheckFrequency",
        js: "paycheckFrequency",
        typ: u(undefined, ""),
      },
    ],
    false
  ),
}
