import { useContext, useEffect } from "react"
import { Outlet } from "react-router-dom"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"

import { postMessageSender } from "../../../Helpers/PostMessage"
import { InstitutionsContext } from "../../../Helpers/Reducers/Institutions"
import { UserContext } from "../../../Helpers/Reducers/User"
import { getRecentUsedInstitutions } from "../../../Helpers/Requests"

export function BankingDetails() {
  const { dispatch } = useContext(InstitutionsContext)

  const {
    state: { user },
  } = useContext(UserContext)

  useEffect(() => {
    async function getResentUserInstitutions() {
      const { data } = await getRecentUsedInstitutions(token, user!.contactId)

      if (data) {
        dispatch({
          type: "setRecentUsedInstitution",
          payload: {
            recentUsedItems: data,
          },
        })
      }
    }

    getResentUserInstitutions()
  }, [])

  const {
    state: { token },
  } = useContext(RequestsContext)

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_banking_loaded",
        },
      },
    })
  }, [])

  return (
    <div className="relative max-w-[622px] text-center sm:w-full sm:mx-auto">
      <Outlet />
    </div>
  )
}
