import { InstantLoanStateType } from "../../../Reducers/types"

export const referenceDetailsParser = (
  state: InstantLoanStateType["referenceDetails"]
): Partial<
  Omit<
    Record<keyof InstantLoanStateType["referenceDetails"], any>,
    "agreeToPolicy" | "agreeToShareInfo" | "agreeToAccurateInfo"
  >
> => {
  const { agreeToPolicy, agreeToAccurateInfo, agreeToShareInfo, ...referenceDetails } = state

  return referenceDetails
}

export const agreementsParser = (
  state: InstantLoanStateType["referenceDetails"]
): { privacyAgreement: boolean } => {
  const { agreeToPolicy, agreeToAccurateInfo, agreeToShareInfo } = state

  return { privacyAgreement: !!agreeToPolicy && !!agreeToAccurateInfo && !!agreeToShareInfo }
}
