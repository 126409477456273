import { useState, useRef, useEffect } from "react"
import SearchNotFound from "../../../../../images/search-not-found.svg"
import { classNameParser } from "@brainfinance/icash-component-library"
import { InputProps } from "@brainfinance/icash-component-library/dist/types/types"
import "./style.css"

export type SearchOption = {
  id: string
  label: string
}

type AsyncSearchInputProps = Omit<
  InputProps<
    string,
    {
      label?: string
      options?: SearchOption[]
      onSelect?: (optionId: string) => void
      getOptions: (term: string) => Promise<SearchOption[]>
      debounceTime?: number
      cleanOnSelect?: boolean
    }
  >,
  "autoComplete"
> & { autoComplete?: string | boolean }

export const AsyncSearchInput = (props: AsyncSearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [value, setValue] = useState<string>(props.defaultValue ?? "")
  const [showOptions, setShowOptions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchOption[]>(props.options || [])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (!props.getOptions) {
      return
    }

    timeout = setTimeout(async () => {
      try {
        setLoading(true)
        const result = await props.getOptions(value)
        setOptions(result)
      } catch (e) {
        console.warn(e)
        setOptions([])
      }
      setLoading(false)
    }, props.debounceTime || 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  const onClickItem = async (item: SearchOption) => {
    setValue(item.label)

    props.onSelect && props.onSelect(item.id)

    if (props.cleanOnSelect) {
      setValue("")
    }

    inputRef.current?.blur()
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    switch (e.key) {
      case "Enter":
        inputRef.current?.blur()
        break
      case "Escape":
        inputRef.current?.blur()
        break
      default:
        break
    }
  }

  return (
    <>
      <div
        id={props.id}
        key={props.key}
        className={classNameParser("global--input-container input-async-search--text-container", props.className)}
      >
        <input
          ref={inputRef}
          value={value}
          placeholder={props.placeHolder}
          name="search"
          type="text"
          inputMode="text"
          autoComplete={props.autoComplete === true ? "on" : props.autoComplete || "off"}
          className={`input-async-search--input ${!props.autoComplete ? "hide-autocomplete" : ""}`}
          onKeyUp={handleKeyPress}
          onBlur={(evt) => {
            evt.preventDefault()
            setShowOptions(false)
          }}
          onFocus={(evt) => {
            evt.preventDefault()
            setShowOptions(true)
          }}
          onChange={(evt) => {
            setShowOptions(true)
            setValue(evt.target.value)
            props.onChange && props.onChange(evt.target.value)
          }}
        />

        {options.length > 0 && showOptions && (
          <div className="input-async-search--options-box">
            <ul className="input-async-search--select-input-options">
              {options.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="input-async-search--select-input-option"
                    onMouseDown={(evt) => {
                      evt.preventDefault()
                      onClickItem(item)
                    }}
                  >
                    {item.label}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {options.length === 0 && showOptions && (
          <div className="input-async-search--options-box tw-flex tw-flex-col tw-justify-center tw-gap-2 py-3">
            <img src={SearchNotFound} alt="Not found" width={32} height={32} className="tw-mx-auto" />
            <h2 className="title-caption tw-text-center">Sorry</h2>
            <p className="caption-light tw-text-center">There is no match found for this search.</p>
          </div>
        )}
        {props.label && (
          <label htmlFor={props.id} className="input-async-search--text-input-label" title={props.label}>
            <span className="input-async-search--text-input-label-text">{props.label}</span>
          </label>
        )}
        {props.error && <p className="global--input-error">{props.error}</p>}
      </div>
    </>
  )
}
