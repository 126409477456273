import { useContext, useEffect } from "react"

import { Button } from "@brainfinance/icash-component-library"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import loanCheckmark from "../../../images/pre-approved-checkmark.svg"
import { UserContext } from "../../../Helpers/Reducers/User"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function LoanPreapproved() {
  const {
    state: { loanParameters },
  } = useContext(InstantLoanContext)
  const {
    state: { user },
  } = useContext(UserContext)

  useEffect(() => {
    postMessageSender({
      event: "loan-preapproved",
    })
  }, [])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_pre_approved_loaded",
        },
      },
    })
  }, [])

  return (
    <div className="max-w-[622px]">
      <p className="caption-light mb-[22px]">
      Congratulations! You're one step away from being approved for your loan.
      </p>
      <p className="caption-light mb-[33px]">
      You{"'"}ll be receiving an email shortly with a link to digitally sign your agreement. Once that{"'"}s done, we{"'"}ll e-Transfer you the full loan amount!
      </p>
      <div className="w-[100%] mb-[40px]">
        <img src={loanCheckmark} className="mx-auto h-[176px]" />
      </div>
      <p className="caption-light mb-[32px] sm:mb-[16.5px]">
      Our team is constantly reviewing loan applications 24/7 so you should be receiving an answer shortly.
      </p>
      <Button
        size="large"
        className="sm:hidden w-full md:text-[16px] md:pb-[10px] block mx-auto"
        appearance="primary"
        onClick={() => postMessageSender({ event: "finished" })}
      >
        Back to Home
      </Button>
    </div>
  )
}
