import { useContext, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Button,
  CheckboxInput,
  InputGroup,
  SelectInput,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { listParser } from "../../../Helpers/Parsers/List"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { financialDetailsParser } from "../../../Helpers/Parsers/Steps/FinancialDetails"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"

import { toast } from "react-toastify"

import { financialDetailsValidators } from "../../../Helpers/Validators/Steps/FinancialDetails"
import { validateValues } from "../../../Helpers/Validators"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { referenceDetailsValidators } from "../../../Helpers/Validators/Steps/ReferenceDetails"
import {
  agreementsParser,
  referenceDetailsParser,
} from "../../../Helpers/Parsers/Steps/ReferenceDetails"

export function FinancialDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [lists, setLists] = useState({
    pastTimeBankruptcy: [],
    otherActiveLoan: [],
    creditScoreEstimation: [],
    // overdueLoans: [],
    currentBankruptcy: [],
    loanPurpose: [],
  })

  const navigate = useNavigate()

  const getAgreed = () => referenceDetails.agreeToShareInfo;

  const {
    state: { financialDetails, isApp, referenceDetails, marketingConsent, marketingConsentUpdated },
    dispatch,
  } = useContext(InstantLoanContext)

  const {
    state: { token },
  } = useContext(RequestsContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  // cleanup details for progress bar / submit
  const cleanupDetails = (details: typeof financialDetails) => {
    let key: keyof typeof details
    for (key in details) if (details[key] === "0") (details[key] as string) = ""

    return details
  }

  const handleValueChange =
    <T extends string | number | Date>(key: keyof typeof financialDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "financialDetails",
        payload: cleanupDetails({ ...financialDetails, [key]: value }),
      })
    }

  const handleValueReferenceChange =
    <T extends string | number | boolean>(key: keyof typeof referenceDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "referenceDetails",
        payload: { ...referenceDetails, [key]: value },
      })
    }

  const handleSubmit = () => {
    const financialDetailsValues = cleanupDetails(financialDetailsParser(financialDetails))

    // validate details
    let validationErrors = validateValues(financialDetailsValues, financialDetailsValidators as {})
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () => document.querySelector(".global--input-error")?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }
    toast.dismiss()

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_financial_information_submitted",
        },
      },
    })

    setIsSubmitting(true)
    postStepRequest(5, financialDetailsValues, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_financial_information_completed",
            },
          },
        })
        handleSubmitReference()
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsSubmitting(false)
      })
  }

  const handleSubmitReference = async () => {
    // validate details
    let validationErrors = validateValues(referenceDetails, referenceDetailsValidators as {})
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () =>
          document
            .querySelector(".global--input-error")
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }

    setIsSubmitting(true)
    try {
      await postStepRequest(6, {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        relationToUser: ""
      }, token)

      await postStepRequest(7, agreementsParser(referenceDetails), token)

      goToNext(navigate, path)
    } catch (e: any) {
      toast(e.message || e, { type: "error" })
      setIsSubmitting(false)
      // scroll to top of document
      document.querySelector("#root")?.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    const onFetchStep6And7 = async () => {
      try {
        const step5Data = await getStepRequest(5, token)

        for (let name in step5Data.list) step5Data.list[name] = listParser(step5Data.list[name])
        setLists(step5Data.list)

        dispatch({
          step: "financialDetails",
          payload: cleanupDetails({ ...step5Data.data }),
        })

        setIsLoading(false)
      } catch (error: any) {
        toast(error?.message ?? "", { type: "error" })
        setIsLoading(false)
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsLoading(false)
      }
    }

      onFetchStep6And7();
  }, [])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_financial_information_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        <div className="grid grid-cols-2 gap-x-[22px] items-start sm:flex sm:flex-col sm:items-stretch">
          <SelectInput
            native={isApp ? false : undefined}
            className="mt-5 sm:mt-0"
            label="What is the purpose of this loan?"
            value={financialDetails.purposeOfLoan}
            error={errors.purposeOfLoan}
            onChange={handleValueChange("purposeOfLoan")}
            options={lists.loanPurpose}
            id="test-purpose-loan"
            disabled={isSubmitting}
          />
          {/* <SelectInput
            native={isApp ? false : undefined}
            className="mt-4 sm:mt-0"
            label="Do you have an overdue loan?"
            value={financialDetails.overdueLoans}
            error={errors.overdueLoans}
            onChange={handleValueChange("overdueLoans")}
            options={lists.overdueLoans}
            id="test-overdue-loan"
            disabled={isSubmitting}
          /> */}
          <SelectInput
            native={isApp ? false : undefined}
            label="How would you describe your credit score?"
            value={financialDetails.creditScoreEstimation}
            error={errors.creditScoreEstimation}
            onChange={handleValueChange("creditScoreEstimation")}
            options={lists.creditScoreEstimation}
            id="test-score-estimation"
            disabled={isSubmitting}
          />
        </div>
        <div className="col-span-2">
        {!marketingConsent && <div className="flex items-start mb-[32px]">
            <CheckboxInput
              large
              label=""
              onChange={(value) => {
                dispatch({
                  step: "marketingConsentUpdated",
                  payload: {
                    marketingConsentUpdated: value ?? false
                  }
                })
              }}
              value={marketingConsentUpdated}
              className="mt-[0px] mb-[0px]"
            >
              <p className="caption">
                I would like to receive promotional content to stay updated
                with Finabanx's new products and offers.
              </p>
            </CheckboxInput>
          </div>}
          <div className="flex items-start">
            <CheckboxInput
              large
              label=""
              className="mt-[0px] mb-[0px]"
              value={referenceDetails.agreeToShareInfo}
              error={errors.agreeToShareInfo}
              onChange={handleValueReferenceChange("agreeToShareInfo")}
            >
              <p className="caption">
                I agree that Finabanx can use my personal information to
                validate my identity and to obtain my credit report*.
                <br />
                <span className="caption !font-400 !text-[12px] !text-[#888D91]">
                  * A one-time verification may not affect your credit score.
                  In addition, returning customers who reapply are exempt from this verification.
                </span>
              </p>
            </CheckboxInput>
          </div>
        </div>
        <div className="flex sm:space-x-0 flex-col">
        <p className="caption text my-[32px]">
            By clicking Continue, I confirm that the information provided is current and accurate and I agree to Finabanx’s 
            {" "}<span 
              onClick={(e) => {
                e.stopPropagation();
                const url = "https://icash.ca/privacy-policy"
                if (!isApp) {
                  window.open(url, "_blank");
                } else {
                  postMessageSender(
                    {
                      event: "display-website",
                      payload: { url: url },
                    }
                  );
                }
              }} 
              className="text-primary cursor-pointer">
                Privacy Policy
            </span> and{" "}
            <span 
              onClick={(e) => {
                e.stopPropagation();
                const url = "https://icash.ca/terms-conditions"
                if (!isApp) {
                  window.open(url, "_blank");
                } else {
                  postMessageSender(
                    {
                      event: "display-website",
                      payload: { url: url },
                    }
                  );
                }
              }} 
              className="text-primary cursor-pointer">
                Terms of Use and Conditions
            </span>.
          </p>
          <div className="flex space-x-[22px] sm:flex-col-reverse sm:space-x-0">
            <Button
              size="large"
              className="flex-1 sm:mt-[22px] sm:py-[13px] sm:text-[16px] sm:hidden"
              onClick={() => goToPrevious(navigate, path)}
              disabled={isSubmitting}
            >
              Previous step
            </Button>
            <Button
              size="large"
              className="flex-1 sm:py-[13px] sm:text-[16px]"
              appearance="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!!Object.values(errors).join("") || !getAgreed() || isSubmitting}
            >
              Continue
            </Button>
          </div>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}
