import { createContext, Dispatch, useReducer } from "react"

type RequestsStateType = {
  token?: string
}

type RequestsActionType = {
  type: "setToken"
  payload: {
    token?: string
  }
}

const requestsReducer = (state: RequestsStateType, action: RequestsActionType) => {
  switch (action.type) {
    case "setToken":
      return { ...state, token: action.payload.token }
    default:
      return state
  }
}

export const useRequestsReducer = () => useReducer(requestsReducer, {}, () => ({ token: "" }))

export const RequestsContext = createContext<{
  state: RequestsStateType
  dispatch: Dispatch<RequestsActionType>
}>({
  state: {},
  dispatch: (_action) => {},
})
