import { InstantLoanStateType } from "../../Reducers/types"

export const loanParser = (state: InstantLoanStateType["loanParameters"]) =>
  JSON.stringify({ terms: state.terms, amount: state.amount })

type BranchMetadataType = {
  userProvince?: string
  numberOfRepayments?: string
  loanAmount?: string
}

export const parseLoanToBranchMetdata = (
  state: InstantLoanStateType["loanParameters"]
): BranchMetadataType => ({
  userProvince: state.province,
  numberOfRepayments: state.terms?.toString(),
  loanAmount: state.amount?.toString(),
})
