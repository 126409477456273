import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { postMessageSender } from "../../../../Helpers/PostMessage"
import { InstitutionsContext } from "../../../../Helpers/Reducers/Institutions"
import { RequestsContext } from "../../../../Helpers/Reducers/Requests"
import { BankType } from "../../../../Helpers/Reducers/types"
import { mariaFetch, searchInstitutions } from "../../../../Helpers/Requests"
import Bank from "./Bank"
import { AsyncSearchInput, SearchOption } from "./search-input"
import { v4 as uuidv4 } from 'uuid';

interface BankingSearch { }

export default function BankingSearch(props: BankingSearch) {
  const navigate = useNavigate()
  const {
    state: { items, recentUsedItems },
  } = useContext(InstitutionsContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const [requestCode] = useState(uuidv4());

  const search = useRef<{ date: string, message: string, step: "SEARCH" }[]>([]);

  useEffect(() => {
    document.querySelector("#scroll-anchor")?.scrollIntoView({ block: "start" })

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_bank_selection_loaded",
        },
      },
    })
  }, [])

  useEffect(() => {
    const sendSearchLogs = async () => {
      if (!search.current.length) return;

      const body = { requestCode, events: search.current }
      search.current = [];

      await mariaFetch('/banking/decision/events/add', token, JSON.stringify(body))
    }

    const searchLogsSendInterval = setInterval(sendSearchLogs, 1000);
    return () => {
      clearInterval(searchLogsSendInterval);
      sendSearchLogs();
    }
  }, [])

  const timer = useRef<ReturnType<typeof setTimeout>>()

  const onChange = (query: string | undefined) => {
    if (timer) clearTimeout(timer.current)

    timer.current = setTimeout((query: string | undefined) => {
      search.current.push({
        date: new Date().toISOString(),
        message: query ?? "",
        step: 'SEARCH'
      })
    }, 300, query)
  }

  const getOptions = useCallback(
    async (query: string): Promise<SearchOption[]> => {
      let institutions: BankType[] = []
      if (!query) {
        institutions = items
      } else {
        const result = await searchInstitutions(query, token)

        if (result.error) {
          institutions = []
        } else {
          institutions = result.data
        }
      }

      return institutions.map(({ nameEn, id, aggregator }) => ({ label: nameEn, id: `${aggregator}::${id}::${nameEn}` }))
    },
    [token]
  )

  return (
    <div className="flex flex-col gap-4">
      <h5 className="text-left subtitle-light-no-color font-400 text-[#13141A]">Select your bank</h5>
      <AsyncSearchInput
        autoComplete={"organization"}
        getOptions={getOptions}
        placeHolder="Type your bank n&#97;me"
        onSelect={(value) => {
          const [aggregator, id] = (value as string).split("::") as [BankType["aggregator"], BankType["id"]]
          navigate(`../${aggregator}/bank/${id}?updateCode=${requestCode}`)
        }}
        onChange={onChange}
      />
      {recentUsedItems.length > 0 ? (
        <>
          <h5 className="text-left subtitle-light-no-color font-400 text-[#13141A]">Recently used</h5>
          <div className="flex">
            <Bank
              value={recentUsedItems[0]}
              iconClass="sm:w-[307px] w-[122px]"
              onClick={() => {
                navigate(`../${recentUsedItems[0].aggregator}/bank/${recentUsedItems[0].id}?updateCode=${requestCode}`)
              }}
            />
          </div>
        </>
      ) : null}
      <h5 className="text-left subtitle-light-no-color font-400 text-[#13141A]">Most popular</h5>
      <div className="grid grid-cols-4 sm:grid-cols-2 gap-2 auto-rows-fr items-center">
        {items.map((item, index) => (
          <Bank
            key={index}
            value={item}
            onClick={() => {
              navigate(`../${item.aggregator}/bank/${item.id}?updateCode=${requestCode}`)
            }}
          />
        ))}
      </div>
    </div>
  )
}
