import { Button, Checklist, ChecklistItem } from "@brainfinance/icash-component-library"
import { Link } from "react-router-dom"
import ServicesLogoImage from "../../../../images/services-logo.svg"
import { HelpBox } from "../../../Utilities/Layout"
import { useEffect } from "react"

export default function Introduction() {
  useEffect(() => {
    document.querySelector("#scroll-anchor")?.scrollIntoView({ block: "start" })
  }, [])

  return (
    <div className="items-center flex-col md:flex block">
      <img src={ServicesLogoImage} className="max-h-[124px]" height={124} width={375} alt="bank's services logo" />
      <h3 className="caption-light-no-color text-[#13141A] my-8 font-600 text-left max-w-[345px] sm:my-6">
        We use Flinks and Plaid to connect your accounts for the best transfer experience
      </h3>
      <Checklist>
        <div className="text-left">
          <ChecklistItem>
            <div className="flex flex-col items-start">
              <p className="caption-medium-no-colors text-[#13141A] mb-2">It's fast</p>
              <p className="caption-medium">Connect your account in seconds</p>
            </div>
          </ChecklistItem>
          <ChecklistItem className="mt-8 sm:mt-6">
            <div className="flex flex-col items-start">
              <p className="caption-medium-no-colors text-[#13141A] mb-2">It's secure</p>
              <p className="caption-medium">Encryption helps protect your personal finance</p>
            </div>
          </ChecklistItem>
          <ChecklistItem className="mt-8 sm:mt-6">
            <div className="flex flex-col items-start">
              <p className="caption-medium-no-colors text-[#13141A] mb-2">It's private</p>
              <p className="caption-medium">Your credentials will never be made accessible to iCash</p>
            </div>
          </ChecklistItem>
        </div>
      </Checklist>
    </div>
  )
}

export function BunkingIntroduction() {
  return (
    <>
      <Introduction />
      <HelpBox className="sm:block hidden sm:mt-6" />
      <Link to={"search"}>
        <Button
          size="large"
          className="w-full sm:py-[13px] sm:text-[16px] mt-8"
          appearance="primary"
          onClick={() => {}}
        >
          Continue
        </Button>
      </Link>
    </>
  )
}
