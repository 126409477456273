import { Button } from "@brainfinance/icash-component-library"
import { useMemo, useState } from "react"
import { BankType } from "../../../../Helpers/Reducers/types"

type BankProps = {
  value: BankType
  onClick: () => void
  iconClass?: string
}

export default function Bank(props: BankProps) {
  const [isError, setIsError] = useState(false)

  return (
    <>
      {isError ? (
        <button className="text-[#13141A] bg-[#F7F7F7] p-4 rounded-full caption-medium-no-colors hover:text-[#4e5972] " onClick={props.onClick}>
          {props.value.nameEn}
        </button>
      ) : (
        <div
          role="button"
          onClick={props.onClick}
          style={{ backgroundColor: props.value.bgColor }}
          className={`rounded-lg py-6 px-5 flex items-center justify-center ${!isError ? "h-[116px]" : ""} ${
            props.iconClass
          }`}
        >
          <img
            onError={() => {
              setIsError(true)
            }}
            src={props.value?.logo ?? ""}
            alt={props.value.nameEn}
            width={124}
            height={48}
          />
        </div>
      )}
    </>
  )
}
