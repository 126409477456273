import { useState } from "react"
import { usePlaidLink, PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess } from "@brainfinance/react-plaid-link"
import type { PlaidEventType } from "../Reducers/PlaidData/types"

export const usePlaid = (clientName: string, env: string, publicKey: string) => {
  const [lastEvent, setLastEvent] = useState<PlaidEventType>()

  const handleEvent: PlaidLinkOnEvent = (eventName, message) => {
    setLastEvent({
      event: {
        eventName: eventName,
        linkSessionId: message.link_session_id,
        viewName: message.view_name ?? "",
        timestamp: message.timestamp,
      },
      raw: message,
    })
  }

  const handleExit: PlaidLinkOnExit = (error, message) => {
    if (error)
      setLastEvent({
        event: {
          eventName: "ERROR",
          linkSessionId: message.link_session_id,
          viewName: "",
          timestamp: new Date().toISOString(),
        },
        raw: message,
      })
  }

  const handleSuccess: PlaidLinkOnSuccess = (token, message) => {
    setLastEvent({
      event: {
        eventName: "SUCCESS",
        linkSessionId: message.link_session_id,
        viewName: "",
        timestamp: new Date().toISOString(),
      },
      raw: { ...message, publicToken: token },
    })
  }

  const { open, ready } = usePlaidLink({
    clientName,
    env,
    publicKey,
    product: ["auth", "assets"],
    countryCodes: ["CA"],
    webhook: process.env.REACT_APP_PLAID_WEBHOOK,
    onEvent: handleEvent,
    onExit: handleExit,
    onLoad: () => {},
    onSuccess: handleSuccess,
  })

  return { lastPlaidEvent: lastEvent, plaidOpen: open, plaidReady: ready }
}
