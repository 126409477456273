import { createContext, useReducer, Dispatch } from "react"
import { UserInfoType } from "../types"

type UserStateType = {
  user?: UserInfoType
}

type UserActionType = {
  type: "setUser"
  payload: {
    user?: UserInfoType
  }
}

const initialUserState: UserStateType = { user: {} }

const userReducer = (state: UserStateType, action: UserActionType) => {
  switch (action.type) {
    case "setUser":
      return { ...state,
        user: {
          ...action.payload.user,
          createdDate: action.payload.user?.createdDate?.replace(" ", "T"), // Handle ISO format for IOS safari date conversion.
          activeLoanCreationDate: action.payload.user?.activeLoanCreationDate?.replace(" ", "T") // Handle ISO format for IOS safari date conversion.
        }
      }
    default:
      return state
  }
}

export const useUserReducer = () => useReducer(userReducer, initialUserState)

export const UserContext = createContext<{
  state: UserStateType
  dispatch: Dispatch<UserActionType>
}>({
  state: initialUserState,
  dispatch: (_action) => {},
})
