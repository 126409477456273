import { createContext, Dispatch, useReducer } from "react"
import type { InstitutionStateType, InstitutionActionType } from "../types"

const institutionsReducer = (state: InstitutionStateType, action: InstitutionActionType) => {
  switch (action.type) {
    case "setInstitutions":
      return { ...state, items: action.payload.items }
    case "setRecentUsedInstitution":
      return { ...state, recentUsedItems: action.payload.recentUsedItems }
    default:
      return state
  }
}

const institutionsReducerState: InstitutionStateType = {
  recentUsedItems: [],
  items: [],
}

export const useInstitutionsReducer = () => {
  return useReducer(institutionsReducer, institutionsReducerState)
}

export const InstitutionsContext = createContext<{
  state: InstitutionStateType
  dispatch: Dispatch<InstitutionActionType>
}>({ state: institutionsReducerState, dispatch: (_action) => {} })
