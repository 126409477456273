
import type { PlaidDataEvent } from "../../../Helpers/Reducers/PlaidData/types"
import type { FlinksDataStateType } from "../../../Helpers/Reducers/FlinksData/types"
import { mariaFetch } from "../../../Helpers/Requests"
import { toast } from "react-toastify"

export async function getAggregators(
  token: string) {
  const response = await mariaFetch(`/banking/aggregators/default`, token)
  const data = await response.json()
  return data
}

export async function postPlaidEvents(token: string, plaidData: PlaidDataEvent) {
  try {
    const response = await mariaFetch(
      `/banking/plaid/events`,
      token,
      JSON.stringify(plaidData)
    )
    if (!response.ok) {
      let body = await response.json()
      console.error(body);
      return false
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function postPlaidAssetReport(token: string, data: {
  linkSessionId: string,
  institutionId: string,
  publicToken: string,
}) {
  try {
    const response = await mariaFetch(
      `/banking/plaid/assetreport`,
      token,
      JSON.stringify({
        linkSessionId: data.linkSessionId,
        institutionId: data.institutionId,
        publicToken: data.publicToken,
      })
    )
    if (!response.ok) {
      let body = await response.json()
      toast(body.error.message || response.statusText, { type: "error" })
      // scroll to top of document
      document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      return false
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function postFlinksDecision(token: string, flinksData: FlinksDataStateType) {
  try {
    const response = await mariaFetch(`/banking/flinks/decision`, token, JSON.stringify(flinksData))
    if (!response.ok) {
      let body = await response.json()
      console.error(body);
      return false
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
