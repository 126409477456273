import { useRef } from "react"
import LogRocket from "logrocket"
import { UserInfoType } from "../Reducers/types"

export function useLogRocket() {
  const isInit = useRef<boolean>(false)

  const init = () => {
    if (process.env.REACT_APP_LOG_ROCKET_LOG_ID) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_LOG_ID, {
        mergeIframes: true,
        parentDomain: process.env.REACT_APP_ICASH_BASE_URL,
      })
      isInit.current = true
    } else {
      console.warn("REACT_APP_LOG_ROCKET_LOG_ID not set.")
    }
  }

  const identify = (user: UserInfoType | undefined) => {
    if (!isInit.current) {
      init()
    }

    if (isInit.current) {
      LogRocket.identify(user?.contactId?.toString() ?? "", {
        name: `${user?.firstName?.trim()} ${user?.lastName?.trim()}`,
        email: user?.email ?? "",
      })
    }
  }

  return { identify, init }
}
