import { postValidatePhoneCompletionParser, validatePhoneParser } from "../Parsers/Steps/ValidatePhone"
import { loanParser } from "../Parsers/Loan"
import { BankType, InstantLoanStateType, RecentBankInfo } from "../Reducers/types"
import * as ErrorType from "../Parsers/FastTrack/Error"
import * as ReviewType from "../Parsers/FastTrack/Review"
import * as Status from "../Parsers/FastTrack/Status"
import * as PayDate from "../Parsers/FastTrack/PayDate"
import { postMessageSender } from "../PostMessage"
import { PayDatesType } from "../Parsers/FastTrack/PayDate"
import { BankSuggestion } from "../../Components/Steps/BankingDetails/types"
import { ContenfulClient } from "../client-contenful"
import { getRecentlyUsedInstitution, setRecentlyUsedInstitution } from "../recentlyUsedInstitution"

const getUrl = () => {
  if (process.env.REACT_APP_MARIA_BASE_URL === undefined || process.env.REACT_APP_MARIA_BASE_URL === "") {
    console.warn("process.env.REACT_APP_MARIA_BASE_URL is empty.")
  }
  return process.env.REACT_APP_MARIA_BASE_URL
}

const stringifyFields = (obj: Record<string, any>) => {
  let result: Record<string, string> = {}

  Object.entries(obj).forEach(([key, value]) => {
    result[key] = value.toString()
  })

  return result
}

export const url = getUrl()

export const APPLICATION_ID = "Nt9b58DxJn$nL6bRA7r027JX8cpewoM%O$cf3$t9^NogqiEwR*"

// FIXME: extract token into env
// Maria fetch helper. If body is provided, then it's POST, otherwise GET
export const mariaFetch = async (path: string, token?: string, body?: BodyInit) => {
  let result
  try {
    result = await fetch(`${url}/${path.slice(path.startsWith("/") ? 1 : 0)}`, {
      method: body ? "POST" : "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Application-ID": APPLICATION_ID,
        ...(body ? { "Content-Type": "application/json" } : {}),
      },
      credentials: process.env.REACT_APP_SANDBOX_ENABLE === "true" ? "include" : undefined,
      body,
    })

    if ([401, 403].includes(result.status)) {
      throw (await result.json()).error
    }
  } catch (e: any) {
    // send error/code to parent window
    postMessageSender({
      event: "error",
      error: e,
    })
    throw e
  }
  return result
}

export const getUserReloadToken = (token?: string) => mariaFetch(`/user/info`, token)

export const getUser = (token?: string) => mariaFetch(`/user/info`, token).then((res) => res.json())

export const getGeneralProvinces = () => mariaFetch(`/general/provinces`).then((res) => res.json())

export const getLoan = (token?: string) =>
  mariaFetch(`/application/loan`, token)
    .then((res) => res.json())
    .then((res) => res.result)

export const getLoanStatus = (token?: string) =>
  mariaFetch(`/user/dashboard/instantloan`, token)
    .then((res) => res.json())
    .then((res) => res.result)

export const postLoan = (state: InstantLoanStateType["loanParameters"], token?: string) =>
  mariaFetch(`/application/loan`, token, loanParser(state))

export const postValidatePhone = (state: InstantLoanStateType["mobileVerification"], token?: string) =>
  mariaFetch(`/application/validatephone`, token, validatePhoneParser(state))
    .then((res) => res.json())
    .then((res) => {
      if (res.error) throw new Error(res.error.message || "error")
      return res
    })
    .then((res) => res.result)

export const postValidatePhoneCompletion = (
  mobileState: InstantLoanStateType["mobileVerification"],
  pin: number,
  token?: string
) =>
  mariaFetch(`/application/validatephonecompletion`, token, postValidatePhoneCompletionParser(mobileState, pin))
    .then((res) => res.json())
    .then((res) => {
      if (res.error) throw new Error(res.error.message || "error")
      return res
    })
    .then((res) => res.result)

export const getStepRequest = (step: number, token?: string) =>
  mariaFetch(`/application/step${step}`, token)
    .then((res) => res.json())
    .then((res) => {
      if (res.error) throw Object.assign(new Error(res.error.message), res.error)
      return {
        data: stringifyFields(res.result.data || res.result),
        list: res.result.list,
      }
    })

export const postStepRequest = (step: number, body: Record<string, any>, token?: string) =>
  mariaFetch(`/application/step${step}`, token, JSON.stringify(body))
    .then((res) => {
      return res.headers.get("Content-Type")?.includes("json") ? res.json() : undefined
    })
    .then((res) => {
      if (res?.error) {
        if (res.error?.message === "This contact has no active application") {
          //FIX when the application no longer exist.
          postMessageSender({
            event: "return",
          })
        }
        throw new Error(res.error.message || "error")
      }
    })

export const getStep1FastTrack = async (
  token?: string
): Promise<{ error?: ErrorType.ErrorType; data?: ReviewType.ReviewType }> => {
  try {
    const res = await mariaFetch(`/application/fasttrack/step1`, token)
    const json = await res.json()
    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    if (json.result) {
      const result = ReviewType.Convert.toReviewType(JSON.stringify(json.result))
      return {
        data: result,
      }
    }
  } catch (error) {}

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const postStep1FastTrack = async (data: { bankEtransfer: number }, token?: string): Promise<boolean> => {
  try {
    const res = await mariaFetch(`/application/fasttrack/step1`, token, JSON.stringify(data))
    if (res.status === 201) {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
  return false
}

export const getStep2FastTrack = async (
  token?: string
): Promise<{ error?: ErrorType.ErrorType; data?: PayDate.PayDatesType }> => {
  try {
    const res = await mariaFetch(`/application/fasttrack/step2`, token)
    const json = await res.json()
    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    if (json.result) {
      const result = json.result as PayDatesType
      return {
        data: result,
      }
    }
  } catch (error) {
    console.warn(error)
  }

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const postStep2FastTrack = async (
  data: { lastPayDate?: string; nextPayDate?: string; consent: boolean },
  token?: string
): Promise<{ error?: { message: string; code: number } }> => {
  try {
    const res = await mariaFetch(`/application/fasttrack/step2`, token, JSON.stringify(data))
    if (res.status === 201) {
      return {}
    }
    const json = await res.json()
    if (json.error) {
      return {
        error: json.error,
      }
    }
    console.warn(json)
  } catch (error) {
    console.warn(error)
  }
  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const postMarketingGroupSubscribe = async (token?: string) => {
  let result
  try {
    result = await fetch(`${url}/marketinggroup/all/subscribe`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Application-ID": APPLICATION_ID,
        "Content-Type": "application/json",
      },
      credentials: process.env.REACT_APP_SANDBOX_ENABLE === "true" ? "include" : undefined,
    })

    if ([401, 403].includes(result.status)) {
      throw (await result.json()).error
    }
  } catch (e: any) {
    // send error/code to parent window
    postMessageSender({
      event: "error",
      error: e,
    })
    throw e
  }
}

export const getStatusFastTrack = async (
  token?: string
): Promise<{
  error?: ErrorType.ErrorType
  data?: Status.FastTrackStatusType
}> => {
  try {
    const res = await mariaFetch(`/application/fasttrack/status`, token)
    const json = await res.json()
    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    if (json.result) {
      const result = Status.Convert.toFastTrackStatusType(JSON.stringify(json.result))
      return {
        data: result,
      }
    }
  } catch (error) {}

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const getMarketingConsent = async (
  token?: string
): Promise<{
  error?: ErrorType.ErrorType
  data?: { subscribedAll: boolean }
}> => {
  try {
    const res = await mariaFetch(`/marketinggroup`, token)
    const json = await res.json()
    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    if (json.result) {
      const result = json.result as { subscribedAll: boolean }
      return {
        data: result,
      }
    }
  } catch (error) {}

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const getMostPopularInstitutions = async (token?: string, size = 10) => {
  try {
    const res = await mariaFetch(`/alicia/banking-institution?page=1&pageSize=${size}`, token)
    const json = await res.json()

    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    return { data: json.institutions as BankType[] }
  } catch (error) {}

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const getRecentUsedInstitutions = async (token?: string, userId?: number) => {
  try {
    const data: BankType[] = getRecentlyUsedInstitution(userId)

    if (data.length > 0) return { data }

    const res = await mariaFetch(`/banking/recentBanks`, token)
    const json = await res.json()

    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    if (json.result.length == 0) return { data: [] }

    const recentBanksInfo: RecentBankInfo[] = json.result

    for await (const recentBankInfo of recentBanksInfo) {
      if (recentBankInfo.plaidId) {
        const bankInfo = await getInstitutionById(token, recentBankInfo.plaidId)

        if (bankInfo.data?.enabled) {
          setRecentlyUsedInstitution(bankInfo.data, userId)
          data.push(bankInfo.data)
          break
        }
      }

      if (recentBankInfo.bankNameOrId) {
        const bankInfo = await getInstitutionById(token, recentBankInfo.bankNameOrId)

        if (bankInfo.data?.enabled) {
          setRecentlyUsedInstitution(bankInfo.data, userId)
          data.push(bankInfo.data)
          break
        }
      }

      const banksInfo = await searchInstitutions(recentBankInfo.bankName, token)

      const bankInfo = banksInfo.data?.find((item) => item.nameEn.includes(recentBankInfo.bankName))

      if (bankInfo) {
        setRecentlyUsedInstitution(bankInfo, userId)
        data.push(bankInfo)
        break
      }
    }

    return {
      data,
    }
  } catch (error) {
    console.warn(error)
  }

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const getInstitutionById = async (token?: string, institutionId?: string) => {
  try {
    const res = await mariaFetch(`/alicia/banking-institution/${institutionId}`, token)
    const json = await res.json()

    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    return { data: json as BankType }
  } catch (error) {
    console.warn(error)
  }

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const searchAutoSuggestion = async (term: string, token?: string) => {
  try {
    const res = await mariaFetch(`/alicia/banking-institution/auto-suggest-search?term=${term}`, token)
    const json = await res.json()

    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    return { data: json.suggestions as BankSuggestion[] }
  } catch (error) {
    console.warn(error)
  }

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}

export const searchInstitutions = async (term: string, token?: string) => {
  try {
    const res = await mariaFetch(`/alicia/banking-institution/search`, token, JSON.stringify({ term }))
    const json = await res.json()

    if (json.error) {
      const error = ErrorType.Convert.toErrorType(JSON.stringify(json.error))
      return {
        error: error,
      }
    }

    return { data: json as BankType[] }
  } catch (error) {
    console.warn(error)
  }

  return {
    error: {
      code: 0,
      message: "Unknown error.",
    },
  }
}
export const getApplicationReviewMessages = async () => {
  if (!ContenfulClient) {
    console.warn(
      "ContenfulClient is undefined.\nREACT_APP_CONTENTFUL_ACCESS_TOKEN OR REACT_APP_CONTENTFUL_SPACE_ID not set."
    )
    return
  }

  try {
    const res = await ContenfulClient.getEntries({
      content_type: "applicationReviewMessages",
    })

    return Object.values(res.items[0].fields) as string[]
  } catch (error) {
    console.warn(error)
  }
}
