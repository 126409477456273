import { InstantLoanStateType } from "../../../Reducers/types"

export const personalDetailsParser = (
  state: InstantLoanStateType["personalDetails"]
): Partial<Record<keyof InstantLoanStateType["personalDetails"], any>> => ({
  ...state,
  gender: 1, // Mr by default
  sin: parseInt(state.sin ? state.sin.replaceAll(" ", "") : "0"),
  dateOfBirth: state.dateOfBirth?.toLocaleDateString("fr-CA") || null, // fall back to null to indicate date needs validation after submit
  levelOfEducation: parseInt(state.levelOfEducation || ""),
  maritalStatus: parseInt(state.maritalStatus || ""),
  numberOfDependants: 1, // Zero by default
  homePhone: '9999999999',
  otherPhone: undefined,
})
