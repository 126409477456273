import { useContext, useState, useEffect, useMemo, useRef, LegacyRef, SyntheticEvent } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Button,
  CostSimulatorLoan,
  CostSimulatorRadioGroup,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import {
  getStatusFastTrack,
  getUser,
  postLoan,
} from "../../../Helpers/Requests"
import { goToNext, goToPersonalDetails } from "../../../Helpers/Routes"
import { findProvinceCodeById } from "../../../Helpers/Parsers/Steps/LoanParameters/loanParameters"

import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { ProvinceType } from "../../../Helpers/Reducers/types"
import { convertStateIdToSlug } from "../../../Helpers/Parsers/Steps/ResidentialDetails"
import { UserContext } from "../../../Helpers/Reducers/User"

export function LoanParameters() {
  const [shouldValidatePhone, setShouldValidatePhone] = useState<boolean>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const [amount, setAmount] = useState<number | undefined>()
  const [province, setProvince] = useState<string>()

  const [licenseDisplay, setLicenseDisplay] = useState<boolean>(false)
  const licenseIframe = useRef<HTMLIFrameElement>();
  const [height, setHeight] = useState<number>(0);

  const {
    state: { loanParameters, loading, provinces },
    dispatch,
  } = useContext(InstantLoanContext)
  const {
    state: { token },
  } = useContext(RequestsContext)
  const {
    state: { user },
  } = useContext(UserContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  const currentProvince = provinces.find((tmp) => tmp.code === loanParameters.province)

  const loanSettingsMaxTerms = useMemo(() => {
    if (loanParameters.province && provinces.length > 0) {
      const province = provinces.find((tmp) => tmp.code === loanParameters.province)
      return province?.maxTerms ?? null
    }
    return null
  }, [loanParameters, provinces])

  // license iframe sends height of its content
  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.source !== licenseIframe.current?.contentWindow) {
        return;
      }

      if (!e.data) return
      let data = JSON.parse(e.data)
      if (data.event !== "size") return
      let iframe = licenseIframe?.current
      if (!iframe) return;
      setHeight(data.payload.height);
    }
    window.addEventListener("message", listener)
    return () => window.removeEventListener("message", listener)
  }, [])

  const handleRadioGroupValueChange =
    (loanParametersValue: typeof loanParameters) => (value: number | undefined) => {
      dispatch({
        step: "loanParameters",
        payload: { ...loanParametersValue, terms: value },
      })
    }

  const handleSubmit = async () => {
    if (
      (String(user?.provinceId) === String(6) || // manitoba
        // 0 loans and account is more than 6 months
        (Number(user?.nbLoans) === 0
          ? isDateOlderThanMonths(user?.createdDate || "", 6)
          : // 3+ loans and the last one was created more than 1y ago
          Number(user?.nbLoans) > 2
          ? isDateOlderThanMonths(user?.activeLoanCreationDate || "", 12)
          : // 1 or 2 loans
            true)) &&
      !licenseDisplay
    ) {
      setLicenseDisplay(true);

      postMessageSender({
        event: "marketing",
        payload: {
          status: "screen",
          data: {
            categoryName: 'Application',
            pathName: "app_license_info_loaded",
            properties: {
              province: currentProvince?.licensePageName
            },
          },
        },
      });

      // we set short rendering delay to force scroll top
      setTimeout(() => {
        licenseIframe.current?.scrollIntoView({ block: "start" })
      })
      return
    }

    try {
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_loan_parameters_submitted",
            properties: {
              loanAmount: loanParameters.amount,
              numberOfRepayment: loanParameters.terms,
            },
          },
        },
      })

      if (licenseDisplay) {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_license_info_submitted",
              properties: {
                province: currentProvince?.licensePageName
              },
            },
          },
        });
      }

      setIsSubmitting(true)

      const applicationLoanResponse = await postLoan(loanParameters, token)
      if (!applicationLoanResponse.ok) {
        const applicationLoanResponseData = await applicationLoanResponse.json() as {error?: {message: string, code: number}};

        toast(applicationLoanResponseData?.error?.message ?? 'Something wrong happened. Please retry.', { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
        setIsSubmitting(false);
        return;
      }

      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_loan_parameters_completed",
            properties: {
              loanAmount: loanParameters.amount,
              numberOfRepayment: loanParameters.terms,
            },
          },
        },
      })

      if (licenseDisplay) {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_license_info_completed",
              properties: {
                province: currentProvince?.licensePageName,
              },
            },
          },
        });
      }

      if (shouldValidatePhone) return goToNext(navigate, path)

      let res = await getStatusFastTrack(token)

      if (res.data?.isFastTrackAvailable && (res.data?.fastTrackWanted ?? true)) {
        return navigate("/fast-track/review", { state: { title: "Review and complete" } })
      }

      goToPersonalDetails(navigate)
    } catch (e) {
      toast(e instanceof Error ? e.message : e + "", { type: "error" })
      // scroll to top of document
      document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      setIsSubmitting(false)
    }
  }

  const onClickDisplaySidePanelForRepaymentOptions = () => {
    postMessageSender({
      event: "repayment-options-side-panel",
      payload: {
        route: `/repayment-options?province=${loanParameters.province}`,
      },
    })
  }

  useEffect(() => {
    getUser(token)
      .then(async (data) => {
        if (data.error) {
          postMessageSender({
            event: "error",
            error: {
              message: data.error.message,
              code: data.error.code,
            },
          })
        } else if (data.result) {
          setShouldValidatePhone(data.result.shouldValidatePhone)

          const provinceCode = findProvinceCodeById(data.result.provinceId)

          setProvince(provinceCode)
        }
      })
      .catch((e) => {
        postMessageSender({
          event: "error",
          error: {
            message: "Something went wrong.",
            code: 500,
          },
        })
      })
  }, [])

  useEffect(() => {
    if (amount !== undefined) {
      dispatch({
        step: "loanParameters",
        payload: { ...loanParameters, amount },
      })
    }
  }, [amount])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_loan_parameters_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader
      loading={
        loading ||
        shouldValidatePhone === undefined ||
        !token ||
        !province ||
        !loanParameters.province
      }
    >
      <div className="max-w-[640px] sm:w-full m-auto">
        <div className="flex flex-col justify-start items-start sm:items-center sm:w-full">
          {currentProvince && <iframe  ref={licenseIframe as LegacyRef<HTMLIFrameElement>} height={licenseDisplay ? height + 10 : 0} src={`${process.env.REACT_APP_ICASH_BASE_URL}/licenses/${currentProvince.licensePageName}`} className={`w-full`} /> }
          {!licenseDisplay ? <CostSimulatorLoan
            defaultValue={loanParameters.amount}
            onChange={setAmount}
            label="Select the amount you’d like to borrow"
            className={`sm:mx-auto sm:scale-[1.1]`}
          /> : null}
          {!licenseDisplay && loanSettingsMaxTerms != null && loanSettingsMaxTerms > 1 && (
            <CostSimulatorRadioGroup
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
              ]}
              value={loanParameters.terms ?? 1}
              onChange={handleRadioGroupValueChange(loanParameters)}
              label="Choose your preferred number of repayments"
              onLearnMore={onClickDisplaySidePanelForRepaymentOptions}
            />
          )}
        </div>
        {loanSettingsMaxTerms != null && loanSettingsMaxTerms <= 1 && !licenseDisplay && (
          <div className="caption mt-[16px] flex flex-1 flex-row items-center">
            The option to select repayments is not available in your province
            <i
              onClick={onClickDisplaySidePanelForRepaymentOptions}
              className={`cursor-pointer not-italic text-primary text-[20px] ml-[8px]`}
            >
              &#9432;
            </i>
          </div>
        )}
        <Button
          size="large"
          appearance="primary"
          className="w-full mt-[16px] md:py-[13px] md:text-[16px]"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </Loader>
  )
}

export function ModifyLoanParameters({ onSubmit }: { onSubmit?: () => void }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [amount, setAmount] = useState<number | undefined>(undefined)

  const {
    state: { loanParameters, loading, provinces },
    dispatch,
  } = useContext(InstantLoanContext)
  const [terms, setTerms] = useState<number | undefined>(loanParameters.terms)

  const loanSettingsMaxTerms = useMemo(() => {
    if (loanParameters.province && provinces.length > 0) {
      const province = provinces.find((tmp) => tmp.code === loanParameters.province)

      return province?.maxTerms ?? null
    }
    return null
  }, [loanParameters, provinces])

  const {
    state: { token },
  } = useContext(RequestsContext)

  const handleSubmit = () => {
    const loanParametersTmp = loanParameters

    if (amount) {
      loanParametersTmp.amount = amount
    }

    if (terms) {
      loanParametersTmp.terms = terms
    }

    dispatch({
      step: "loanParameters",
      payload: { ...loanParametersTmp },
    })

    postLoan(loanParametersTmp, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_loan_parameters_updated",
              properties: {
                loanAmount: loanParametersTmp.amount,
                numberOfRepayment: loanParametersTmp.terms,
              },
            },
          },
        })
        setIsSubmitting(true)
        onSubmit?.()
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    if (loanSettingsMaxTerms != null && loanSettingsMaxTerms > 1) {
      setTerms(loanParameters.terms ?? 1)
    }
  }, [loanParameters.terms, loanSettingsMaxTerms])

  return (
    <Loader loading={loading || !token}>
      <div className="max-w-[529px] sm:w-full">
        <div className="flex flex-col justify-start items-start sm:w-full sm:items-center">
          <CostSimulatorLoan
            defaultValue={loanParameters.amount}
            onChange={setAmount}
            label="Select the amount you’d like to borrow"
            className="sm:ml-auto sm:mr-auto"
          />
          {loanSettingsMaxTerms != null && loanSettingsMaxTerms > 1 && (
            <CostSimulatorRadioGroup
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
              ]}
              value={terms ?? 1}
              onChange={setTerms}
              label="Choose your preferred number of repayments"
            />
          )}
        </div>
        <Button
          size="large"
          appearance="primary"
          className="w-full"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Confirm changes
        </Button>
      </div>
    </Loader>
  )
}

function isDateOlderThanMonths(dateString: string, months: number) {
  const date = new Date(dateString)
  const currentDate = new Date()
  const monthDifference =
    (currentDate.getFullYear() - date.getFullYear()) * 12 +
    (currentDate.getMonth() - date.getMonth())
  return monthDifference >= months
}
